import React from "react";
import { useParams } from "react-router-dom";
import Hguec from "./Web_hguec";
import StuClub from "./Web_stuClub";
import Stugov from "./Web_Stugov";

const ResourceOutlet = () => {
  const { id } = useParams();

  return (
    <>
      {id === "stugov" ? (
        <Stugov />
      ) : id === "hguec" ? (
        <Hguec/>
      ) : id === "stuclub" ? (
        <StuClub/>
      ) : (
        <></>
      )
      }
    </>
  );
};

export default ResourceOutlet;
