import React, { useState } from "react";
import DumeDataImg from "../../../Assets/Img/ConvenienceInfo_Img/HGUShop/Dataimg.png";
import styled, { ThemeProvider } from "styled-components";
import { theme } from "../../../Style/theme";
import { Link } from "react-router-dom";

const categories = ['식사', '카페', '피티니스', '미용', '기타'];
const dummyData = [];

for (let i = 1; i <= 100; i++) {
  const randomCategoryIndex = Math.floor(Math.random() * categories.length);
  const randomCategory = categories[randomCategoryIndex];

  dummyData.push({
    id: i,
    image: DumeDataImg,
    title: `Dummy Title ${i}`,
    author: `Author ${i}`,
    date: '2023-07-01',
    category: randomCategory,
    views: Math.floor(Math.random() * 100)
  });
}

const Header2 = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Header2};
  font-weight: ${(props) => props.theme.fontWeights.Header2};
  line-height: ${(props) => props.theme.LineHeight.Header2};
  font-family: "Pretendard";
  color: #3f3f37;
`;

const Caption1 = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Caption1};
  font-weight: ${(props) => props.theme.fontWeights.Caption1};
  line-height: ${(props) => props.theme.LineHeight.Caption1};
  font-family: "Pretendard";
  color: #3f3f37;
`;

const Caption4 = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Caption4};
  font-weight: ${(props) => props.theme.fontWeights.Caption4};
  line-height: ${(props) => props.theme.LineHeight.Caption4};
  font-family: "Pretendard";
  color: #cfd4d9;
  margin-top: 160px;
  margin-bottom: 8px;
`;

const PreviewImg = styled.img`
  width: 224px;
  height: 224px;
`;

const FilterDiv = styled.div`
  width: 720px;
  height: 48px;
  margin-top: 56px;
`;

const FilterButton = styled.button`
  width: 120px;
  height: ${(props) => (props.isActive ? "46px" : "48px")};
  background-color: white;
  color: ${(props) => (props.isActive ? "#3F3F37" : "#9C9C9C")};
  font-size: ${(props) => (props.isActive ? "16px" : "14px")};
  line-height: ${(props) => (props.isActive ? "20px" : "18Px")};
  font-weight: ${(props) => (props.isActive ? 700 : 500)};
  font-family: "Pretendard";
  border-top: ${(props) =>
    props.isActive ? "1px solid #143551" : "1px solid #D0D0D0"};
  border-bottom: ${(props) => (props.isActive ? "none" : "1px solid #143551")};
  border-left: ${(props) =>
    props.isActive ? "1px solid #143551" : "0.5px solid #D0D0D0"};
  border-right: ${(props) =>
    props.isActive ? "1px solid #143551" : "0.5px solid #D0D0D0"};
`;

const FirstDiv = styled.div`
  width: 224px;
  height: 22px;
  /* background-color: red; */
  display: flex;
  margin-top: 24px;
`;

const SecondDiv = styled.div`
  width: 224px;
  height: 16px;
  /* background-color: blue; */
  display: flex;
  margin-top: 16px;
`;

const PageButtonDiv = styled.div`
  width: 350px;
  height: 32px;
  /* background-color: green; */
  margin-top: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FirstArrowButton = styled.button`
  width: 32px;
  height: 32px;
  border: 1px solid #9c9c9c;
  border-right: ${(props) => props.border}px solid #9c9c9c;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${(props) => props.right}px;
  cursor: pointer;
`;

const PageButton = styled.div`
  font-size: 16px;
  font-family: "Pretendard";
  font-weight: 600;
  line-height: 20px;
  color: ${(props) => (props.isActive ? "#3F3F37" : "#D0D0D0")};
  width: 10px;
  height: 20px;
  margin-left: 32px;
  cursor: pointer;
`;

const BeforeButton = styled.button`
  width: 32px;
  height: 32px;
  border: 1px solid #9c9c9c;
  border-left: 0.5px solid #9c9c9c;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -8px;
  cursor: pointer;
`;

const SingleArrow = styled.img`
  width: 6.5px;
  height: 8px;
`;

const PageArrowButton = styled.button`
  width: 32px;
  height: 32px;
  border: 1px solid #9c9c9c;
  border-right: 0.5px solid #9c9c9c;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 24px;
  cursor: pointer;
`;

const DoubleArrow = styled.img`
  width: 12.5px;
  height: 8px;
`;

const LastArrowButton = styled.button`
  width: 32px;
  height: 32px;
  border: 1px solid #9c9c9c;
  border-left: 0.5px solid #9c9c9c;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const NoContentsImg = styled.img`
  width: 80px;
  height: 80px;
`;

const YourComponent = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState("전체"); // State for selected category
  const itemsPerPage = 9;
  const totalPages = Math.ceil(
    (selectedCategory === "전체"
      ? dummyData.length
      : dummyData.filter((item) => item.category === selectedCategory).length) /
      itemsPerPage
  );

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const filteredData =
    selectedCategory === "전체"
      ? dummyData.slice(startIndex, endIndex)
      : dummyData
          .filter((item) => item.category === selectedCategory)
          .slice(startIndex, endIndex);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setCurrentPage(1); // Reset current page when changing category
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0); 
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
    window.scrollTo(0, 0); 
  };

  const handleNextPage = () => {
    const nextPage = Math.ceil(currentPage / 5) * 5 + 1;
    if (nextPage <= totalPages) {
      setCurrentPage(nextPage);
      window.scrollTo(0, 0); 
    } else {
      setCurrentPage(totalPages);
      window.scrollTo(0, 0); 
    }
  };

  const handleBeforePage = () => {
    if (currentPage > 1) {
      const currentRangeStart = Math.floor((currentPage - 1) / 5) * 5 + 1;
      setCurrentPage(currentRangeStart - 5);
      window.scrollTo(0, 0); 
    }
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
    window.scrollTo(0, 0); 
  };

  const BoxComponent = ({ data }) => {
    const BoxGrid = styled.div`
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 48px;
      column-gap: 24px;
      margin-top: 40px;
    `;

    const BoxItem = styled(Link)`
      cursor: pointer;
      background-color: #ffffff;
      border: none;
      text-decoration: none;
    `;

    return (
      <BoxGrid>
        {data.map((item) => (
          <BoxItem to={`/campinfo/hgushop/${item.id}`} key={item.id}>
            {" "}
            <PreviewImg src={item.image} alt={item.title} />
            <FirstDiv>
              <Header2>[{item.category}]</Header2>
              <Header2>&nbsp;{item.title}</Header2>
            </FirstDiv>
            <SecondDiv>
              <Caption1>{item.date}&nbsp;</Caption1>
              <Caption1>|&nbsp;{item.views}&nbsp;</Caption1>
              <Caption1>|&nbsp;{item.author}</Caption1>
            </SecondDiv>
          </BoxItem>
        ))}
      </BoxGrid>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <FilterDiv>
        <FilterButton
          isActive={selectedCategory === "전체"}
          onClick={() => handleCategoryChange("전체")}
        >
          전체
        </FilterButton>
        <FilterButton
          isActive={selectedCategory === "식사"}
          onClick={() => handleCategoryChange("식사")}
        >
          식사
        </FilterButton>
        <FilterButton
          isActive={selectedCategory === "카페"}
          onClick={() => handleCategoryChange("카페")}
        >
          카페
        </FilterButton>
        <FilterButton
          isActive={selectedCategory === "피티니스"}
          onClick={() => handleCategoryChange("피티니스")}
        >
          피티니스
        </FilterButton>
        <FilterButton
          isActive={selectedCategory === "미용"}
          onClick={() => handleCategoryChange("미용")}
        >
          미용
        </FilterButton>
        <FilterButton
          isActive={selectedCategory === "기타"}
          onClick={() => handleCategoryChange("기타")}
        >
          기타
        </FilterButton>
      </FilterDiv>
      <BoxComponent data={filteredData} />
      {totalPages === 0 ? (
        <>
          <Caption4>아직 컨텐츠가 없습니다.</Caption4>
          <NoContentsImg
            src={require("../../../Assets/Img/NoticeImg/NoContentsImg.png")}
          />
        </>
      ) : (
        <PageButtonDiv>
          {currentPage === 1 ? (
            <></>
          ) : currentPage > 5 ? (
            <>
              <FirstArrowButton
                border={0.5}
                right={0}
                onClick={handleFirstPage}
                disabled={currentPage === 1}
              >
                <DoubleArrow
                  src={require("../../../Assets/Img/ConvenienceInfo_Img/HGUShop/MoveFirstArrow.png")}
                />
              </FirstArrowButton>
              <BeforeButton
                onClick={handleBeforePage}
                disabled={currentPage === 1}
              >
                <SingleArrow
                  src={require("../../../Assets/Img/ConvenienceInfo_Img/HGUShop/BackArrow.png")}
                />
              </BeforeButton>
            </>
          ) : (
            <FirstArrowButton
              border={1}
              right={-8}
              onClick={handleFirstPage}
              disabled={currentPage === 1}
            >
              <DoubleArrow
                src={require("../../../Assets/Img/ConvenienceInfo_Img/HGUShop/MoveFirstArrow.png")}
              />
            </FirstArrowButton>
          )}
          {Array.from({ length: totalPages }, (_, index) => index + 1)
            .filter(
              (pageNumber) =>
                pageNumber >= (Math.ceil(currentPage / 5) - 1) * 5 + 1 &&
                pageNumber <= (Math.ceil(currentPage / 5) - 1) * 5 + 5
            )
            .map((pageNumber) => (
              <PageButton
                key={pageNumber}
                onClick={() => handlePageChange(pageNumber)}
                disabled={pageNumber === currentPage}
                isActive={pageNumber === currentPage}
              >
                {pageNumber}
              </PageButton>
            ))}
          {currentPage === totalPages ? (
            <></>
          ) : (
            <>
              <PageArrowButton
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                <SingleArrow
                  src={require("../../../Assets/Img/ConvenienceInfo_Img/HGUShop/NextArrow.png")}
                />
              </PageArrowButton>
              <LastArrowButton
                onClick={handleLastPage}
                disabled={currentPage === totalPages}
              >
                <DoubleArrow
                  src={require("../../../Assets/Img/ConvenienceInfo_Img/HGUShop/MoveLastArrow.png")}
                />
              </LastArrowButton>
            </>
          )}
        </PageButtonDiv>
      )}
    </ThemeProvider>
  );
};

export default YourComponent;
