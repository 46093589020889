import styled, { ThemeProvider } from 'styled-components';
import { theme } from '../../../Style/theme';
import React from 'react';

const Header1 = styled.div`
  font-size: ${props => props.theme.Web_fontSizes.Header1};
  font-weight: ${props => props.theme.fontWeights.Header1};
  line-height: ${props => props.theme.LineHeight.Header1};
  font-family: 'Pretendard';
  text-decoration: none;
  margin-right: 50px;
  color: #3F3F37;
`;

const RightDiv = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 745px;
   height: 2000px;
   /* background-color: red; */
`;

const HR = styled.div`
width: 755px;
height: 0px;
left:0px;
margin-bottom: 24px;
margin-top: 56px;
border: 2px solid #143551;
`

const ConvenienceInfoImg = styled.img`
   width: 499px;
   height: 499px;
   margin-top: 24px;
   margin-right: 246px;
`;



const ConvenienceSecond = () => {

  return (
    <ThemeProvider theme={theme}>
      <RightDiv>
        <Header1>물품 리스트</Header1>
        <HR />
        <ConvenienceInfoImg src={require('../../../Assets/Img/ConvenienceInfo_Img/articleList1.png')} />
        <ConvenienceInfoImg src={require('../../../Assets/Img/ConvenienceInfo_Img/articleList2.png')} />
        <ConvenienceInfoImg src={require('../../../Assets/Img/ConvenienceInfo_Img/articleList3.png')} />
        <ConvenienceInfoImg src={require('../../../Assets/Img/ConvenienceInfo_Img/articleList4.png')} />
        <ConvenienceInfoImg src={require('../../../Assets/Img/ConvenienceInfo_Img/articleList5.png')} />
        <ConvenienceInfoImg src={require('../../../Assets/Img/ConvenienceInfo_Img/articleList6.png')} />

      </RightDiv>
    </ThemeProvider>
  );
};

export default ConvenienceSecond;