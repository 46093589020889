import React from 'react';
import SuggestionBoard from '../Components/Web/Communication_Components/Web_Communocation.js';
import Footer from '../Components/Web/Footer/Footer.js';
import NavBar from '../Components/Web/NavBar/Web_NavBar';

const Communication = () => {
  return (
    <div>
        <NavBar/>
        <SuggestionBoard/>
        <Footer/>
    </div>
  );
};

export default Communication;
