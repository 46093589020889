import React from "react";
import { useParams } from "react-router-dom";
import InquiryPage from "./Web_ InquiryBoard";
import SuggestionBoardPage from "./Web_SuggestBoard";

const CommunicationOutlet = () => {
  const { id } = useParams();

  return (
    <>
      {id === "suggest" ? (
        <SuggestionBoardPage />
      ) : id === "inquiry" ? (
        <InquiryPage />
      ) : (
        <></>
      )}
    </>
  );
};

export default CommunicationOutlet;
