import React from 'react';
import NavBar from '../Components/Web/NavBar/Web_NavBar';
import ResourceLibraryComponents from '../Components/Web/ResourceLibrary_Components/Web_ResourceLibrary';

const ResourceLibrary = () => {
  return (
    <div>
         <NavBar/>
         <ResourceLibraryComponents/>
    </div>
  );
};

export default ResourceLibrary;
