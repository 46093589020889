import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MainHome from "./Page/MainHome";
import StudentCouncil from "./Page/StudentCouncil";
import ConvenienceInfo from "./Page/ConvenienceInfo";
import Communication from "./Page/Communication";
import NoticeBoard from "./Page/NoticeBoard";
import ResourceLibrary from "./Page/ResourceLibrary";
import ScrollToTop from "./ScrollToTop";
import LoginPage from "./Components/Web/Login/Web_LoginPage";
import StuCouncilCopmpnents from "./Components/Web/StudentCouncil_Components/StuCouncil_components";
import ConvenienceInfoComponents from "./Components/Web/ConvenienceInfo_Components/ConvenienceInfo_components";
import HguShopDetail from "./Components/Web/ConvenienceInfo_Components/HGUSHOP_DetailPage";
import NoticeDetail from "./Components/Web/Notice_Components/Notice_DetailPage";
import CommunicationOutlet from "./Components/Web/Communication_Components/Outlet_components";
import ResourceOutlet from "./Components/Web/ResourceLibrary_Components/Resource_Outlet_components";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<MainHome />} />
        <Route path="/Login" element={<LoginPage />} />
        <Route path="/about" element={<StudentCouncil />}>
          <Route path=":id" element={<StuCouncilCopmpnents />} />
        </Route>
        <Route path="/campinfo" element={<ConvenienceInfo />}>
          <Route path=":id" element={<ConvenienceInfoComponents />} />
        </Route>
        <Route path="/help" element={<Communication />}>
          <Route path=":id" element={<CommunicationOutlet />} />
        </Route>
        <Route path="/notice" element={<NoticeBoard />}></Route>
        <Route path="/library" element={<ResourceLibrary />}>
          <Route path=":id" element={<ResourceOutlet />} />
        </Route>
        <Route path="/campinfo/hgushop/:id" element={<HguShopDetail />} />
        <Route path="/notice/:id" element={<NoticeDetail />} />
      </Routes>
    </Router>
  );
}

export default App;
