import { Link, Outlet } from "react-router-dom";
import React, { useState, useEffect } from "react";
import styled, { ThemeProvider } from "styled-components";
import { theme } from "../../../Style/theme";
import { useParams } from "react-router-dom";

const Body1 = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Body1};
  font-weight: ${(props) => (props.color === "0" ? "600" : "400")};
  line-height: ${(props) => props.theme.LineHeight.Body1};
  font-family: "Pretendard";
  text-decoration: none;
  color: ${(props) => (props.color === "0" ? "#FFFFFF" : "#3F3F37")};
`;

const BodyText2 = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Body1};
  font-weight: ${(props) => (props.color === "2" ? "600" : "400")};
  line-height: ${(props) => props.theme.LineHeight.Body1};
  font-family: "Pretendard";
  text-decoration: none;
  color: ${(props) => (props.color === "2" ? "#FFFFFF" : "#3F3F37")};
`;

const BodyText3 = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Body1};
  font-weight: ${(props) => (props.color === "3" ? "600" : "400")};
  line-height: ${(props) => props.theme.LineHeight.Body1};
  font-family: "Pretendard";
  text-decoration: none;
  color: ${(props) => (props.color === "3" ? "#FFFFFF" : "#3F3F37")};
`;

const Header3 = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Header3};
  font-weight: ${(props) => props.theme.fontWeights.Header3};
  line-height: ${(props) => props.theme.LineHeight.Header3};
  font-family: "Pretendard";
  text-decoration: none;
  color: #3f3f37;
  margin-bottom: 32px;
`;

const Div = styled.div`
  margin: 0px auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 100%;
`;

const PartDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: ${(props) => (props.color === "1" ? "1340px" : "1330px")};
  align-items: center;
  /* background-color: red; */
  margin: 0px auto;
  padding-right: 5px;
  margin-top: 100px;
`;

const TitleDiv = styled.div`
  display: flex;
  height: 38px;
  justify-content: start;
  width: 100%;
  align-items: center;
  /* background-color: green; */
  margin-bottom: 56px;
`;

const HRLight = styled.div`
  position: absolute;
  width: 99.9vw;
  height: 0px;
  left: 0px;
  margin-top: 40px;
  border: 0.8px solid #d0d0d0;
  overflow-x: hidden;
`;

const Home = styled.img`
  width: 16px;
  height: 16px;
  margin-left: 2px;
`;

const RightAllow = styled.img`
  width: 5px;
  height: 9px;
  margin-left: 8px;
`;

const WhereText = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  font-family: "Pretendard";
  text-decoration: none;
  color: #3f3f37;
  margin-left: 8px;
`;

const MainDiv = styled.div`
  display: flex;
  height: 200px;
  justify-content: center;
  width: 100%;
  /* background-color: blue; */
`;

const LeftDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 190px;
  height: 156px;
  /* background-color: yellow; */
  margin-right: 27px;
  margin-top: 35px;
`;

const LeftDivButton1 = styled.button`
  width: 190px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: ${(props) => (props.color === "0" ? "#143551" : "#FFFFFF")};
  border: 0.5px solid rgba(63, 63, 55, 1);
  border-left: none;
  border-right: none;
  border-top: 1px solid rgba(63, 63, 55, 1);
  cursor: pointer;
`;

const LeftDivButton3 = styled.button`
  width: 190px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: ${(props) => (props.color === "2" ? "#143551" : "#FFFFFF")};
  border: 0.5px solid rgba(63, 63, 55, 1);
  border-left: none;
  border-right: none;
  cursor: pointer;
`;

const LeftDivButton4 = styled.button`
  width: 190px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: ${(props) => (props.color === "3" ? "#143551" : "#FFFFFF")};
  border: 0.5px solid rgba(63, 63, 55, 1);
  border-left: none;
  border-right: none;
  border-bottom: 1px solid rgba(63, 63, 55, 1);
  cursor: pointer;
`;

const NavItem = styled(Link)`
  text-decoration: none;
`;

const ResourceLibraryComponents = () => {
  const [isSuggest, setIsSuggests] = useState("0"); // 인사 UI
  const { id } = useParams();

  useEffect(() => {
    if (id === "suggest") {
      setIsSuggests("0");
    } else if (id === "inquiry") {
      setIsSuggests("1");
    }
  }, [id]);

  const handleClickGreetings = () => {
    setIsSuggests("0");
  };
  const handleClickorganization2 = () => {
    setIsSuggests("2");
  };
  const handleClickorganizatio3 = () => {
    setIsSuggests("3");
  };

  return (
    <Div>
      <ThemeProvider theme={theme}>
        <PartDiv color={isSuggest}>
          <TitleDiv>
            <Home
              src={require("../../../Assets/Img/StudentCouncil_Img/HomeIcon.png")}
            />
            <RightAllow
              src={require("../../../Assets/Img/StudentCouncil_Img/MiniRightIcon.png")}
            />
            <WhereText>자료실</WhereText>
            <RightAllow
              src={require("../../../Assets/Img/StudentCouncil_Img/MiniRightIcon.png")}
            />
            {isSuggest === "0" ? (
              <WhereText>총학생회</WhereText>
            ) : isSuggest === "1" ? (
              <WhereText>자치회</WhereText>
            ) : isSuggest === "2" ? (
              <WhereText>총동아리연합회</WhereText>
            ) : (
              <WhereText>중앙선거관리 위원회</WhereText>
            )}
          </TitleDiv>
          <HRLight />
          <MainDiv>
            <LeftDiv>
              <Header3>자료실</Header3>
              <NavItem to="/library/stugov">
                <LeftDivButton1
                  onClick={handleClickGreetings}
                  color={isSuggest}
                >
                  <Body1 color={isSuggest}>총학생회</Body1>
                </LeftDivButton1>
              </NavItem>
              <NavItem to="/library/stuclub">
                <LeftDivButton3
                  onClick={handleClickorganization2}
                  color={isSuggest}
                >
                  <BodyText2 color={isSuggest}>총동아리연합회</BodyText2>
                </LeftDivButton3>
              </NavItem>
              <NavItem to="/library/hguec">
                <LeftDivButton4
                  onClick={handleClickorganizatio3}
                  color={isSuggest}
                >
                  <BodyText3 color={isSuggest}>중앙선거관리 위원회</BodyText3>
                </LeftDivButton4>
              </NavItem>
            </LeftDiv>
            <Outlet />
          </MainDiv>
        </PartDiv>
      </ThemeProvider>
    </Div>
  );
};

export default ResourceLibraryComponents;
