import React from 'react';
import { Link } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import { theme } from '../../../Style/theme';

// 하람씨 여기 코드 바꾸시면 돼요~


const Caption1 = styled.div`
  font-size: ${props => props.theme.Web_fontSizes.Caption1};
  font-weight: ${props => props.theme.fontWeights.Caption1};
  line-height: ${props => props.theme.LineHeight.Caption1};
  font-family: 'Pretendard';
  text-decoration: none;
  cursor: pointer;
  color: rgba(63, 63, 55, 1);
  margin-left: ${props => props.left}px;
`;

const A = styled.a`
  &:hover {
    background-color: #3F3F37;
    color: white;
    ${Caption1} {
      color: white;
      background-color: #3F3F37;
      font-size: ${props => props.theme.Web_fontSizes.Caption2};
      font-weight: ${props => props.theme.fontWeights.Caption2};
      line-height: ${props => props.theme.LineHeight.Caption2};
    }
  }
  width: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  text-decoration: none;
  margin-left: ${props => props.left}px;
`

const Caption3 = styled.div`
  font-size: ${props => props.theme.Web_fontSizes.Caption3};
  font-weight: ${props => props.theme.fontWeights.Caption3};
  line-height: ${props => props.theme.LineHeight.Caption3};
  font-family: 'Pretendard';
  text-decoration: none;
  margin-left: ${props => props.left}px;
  cursor: pointer;
  color: rgba(20, 53, 81, 1);
`;

const Div = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: stretch;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
`;


const NavBarWrapperDiv = styled.nav`
  background-color: rgba(20, 53, 81, 1);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;  
  align-items: center;
  border-bottom: 0.5px solid #D0D0D0;
  width: 100%;
`;

const NavBarWrapper1 = styled.nav`
  background-color: white;
  display: flex;
  justify-content: center;
  height: 34px;
  align-items: center;
  border: none;
  width: 100%;
  min-width: 1600px;
  max-width: 2000px;
`;

const NavBarWrapper1Left = styled.nav`
  display: flex;
  justify-content: center;
`;

const NavBarWrapper1Right = styled.nav`
  display: flex;
`;


const NavBarWrapper2 = styled.nav`
  background-color: rgba(20, 53, 81, 1);
  color: white;
  display: flex;
  justify-content: center;
  height: 64px;
  align-items: center;
  min-width: 1600px;
  max-width: 2000px;
`;

const NavLink = styled(Link)`
  text-decoration: none;
`;

const Logo = styled.div`
  img {
    height: 44px;
    width: 70px;
  }
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 36px;
  background: #FFFFFF;
  border-radius: 30px;
  border: none; /* 테두리 제거 */
  text-decoration: none;
  cursor: pointer;
  margin-left: ${props => props.left}px;
`;

const LoginButton = styled.div`
padding-bottom: 3px;
`;


const Dropdown = styled.div`
  display: none;
  position: absolute;
  background-color: #3F3F37;
  min-width: 120px;
  min-height: 40px;
  box-shadow: #D9D9D9;
  transform: translateX(${props => props.transformX}%) translateY(${props => props.transformY}%); 
  justify-content: center;
  align-items: center;
  border-top: 4px solid #EEEE8F;
 `;
 
const NavItemLink = styled(NavLink)`
  text-decoration: none;
  margin-left: ${props => props.left}px;
  font-size: ${props => props.theme.Web_fontSizes.Body1};
  font-weight: ${props => props.theme.fontWeights.Body1};
  line-height: ${props => props.theme.LineHeight.Body1};
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  transition: background-color 100s;
  
 &:hover {
   background-color: #EEEE8F;
   color: #EEEE8F;
 }
`;
 
const DropdownLink = styled(NavLink)`
  color: #B2B7BB;
  padding: 17px 0px 0px 12px;
  text-decoration: none;
  width: 140px;
  min-height: 40px;
  display: block;
  text-align: left;
  transition: background-color 100s;
  border-bottom: 0.5px solid #000000;
 
 &:hover {
   color: white;
 }
`;
 
const Hoverable = styled.div`
  position: relative;
  display: inline-block;
  &:hover ${Dropdown} {
   display: block;
  }
 `;

 
const NavBar = () => {
  const handleClick = () => {
    window.open("https://map.naver.com/v5/entry/place/1816666857?lng=129.3881109&lat=36.1036575&placePath=%2Fhome%3Fentry=plt", "_blank");
  };

  return (
    <Div>
      <ThemeProvider theme={theme}>
        <NavBarWrapperDiv>
          <NavBarWrapper1>
            <NavBarWrapper1Left>
              <A href="https://www.handong.edu" target="_blank" left={0}><Caption1>한동대학교</Caption1></A>
              <A href="https://hisnet.handong.edu/" target="_blank" left={24}><Caption1>HISNET</Caption1></A>
              <A href="https://www.instagram.com/hgu_for_stu/" target="_blank" left={24}><Caption1>인스타그램</Caption1></A>
              <A href="https://www.youtube.com/@28WITH/streams" target="_blank" left={24}><Caption1>유튜브</Caption1></A>
              <A href="https://pf.kakao.com/_xfAunb" target="_blank" left={24}><Caption1  >카카오톡 채널</Caption1></A>
            </NavBarWrapper1Left>
            <NavBarWrapper1Right>
              <LoginButton>
                <NavLink to="/Login"><Caption1 left={560}>로그인</Caption1></NavLink>
              </LoginButton>
            </NavBarWrapper1Right>
          </NavBarWrapper1>
          <NavBarWrapper2>
            <Logo>
              <Link to="/">
                <img src={require("../../../Assets/Img/Logo/MainLogo.png")} alt="Logo" />
              </Link>
            </Logo>
            <Hoverable>
              <NavItemLink left={100} to="/about/greet">총학생회</NavItemLink>
                <Dropdown transformX={45} transformY={3}>
                  <DropdownLink to="/about/greet">인사말</DropdownLink>
                  <DropdownLink to="/about/department">조직도</DropdownLink>
                </Dropdown>
            </Hoverable>
            <Hoverable>
              <NavItemLink left={100} to="/notice">공지사항</NavItemLink>
            </Hoverable>
            <Hoverable>
              <NavItemLink left={100} to="/help/suggest">소통</NavItemLink>
                <Dropdown transformX={35} transformY={3}>
                  <DropdownLink to="/help/suggest">건의게시판</DropdownLink>
                  <DropdownLink to="/help/inquiry">문의게시판</DropdownLink>
                </Dropdown>
            </Hoverable>
            <Hoverable>
              <NavItemLink left={100} to="/campinfo/hgushop">편의정보</NavItemLink>
                <Dropdown transformX={45} transformY={3}>
                  <DropdownLink to="/campinfo/hgushop">HGU SHOP</DropdownLink>
                  <DropdownLink to="/campinfo/rentlist">물품 리스트</DropdownLink>
                </Dropdown>
            </Hoverable>
            <Hoverable>
              <NavItemLink left={100} to="/library/stugov">자료실</NavItemLink>
                <Dropdown transformX={40} transformY={2}>
                  <DropdownLink to="/library/stugov">총학생회</DropdownLink>
                  <DropdownLink to="/library/stuclub">총동아리연합회</DropdownLink>
                  <DropdownLink to="/library/hguec">중앙선거관리위원회</DropdownLink>
                </Dropdown>
            </Hoverable>
            <Button left={110} onClick={handleClick}>
              <Caption3 >네이버 예약 바로가기</Caption3>
            </Button>
          </NavBarWrapper2>
        </NavBarWrapperDiv>
      </ThemeProvider>
    </Div>
  );
};

export default NavBar;

