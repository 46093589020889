import React from "react";
import Footer from "../Components/Web/Footer/Footer";
import NavBar from "../Components/Web/NavBar/Web_NavBar";
import NoticeComponents from "../Components/Web/Notice_Components/Notice_Components";

const NoticeBoard = () => {
  return (
    <div>
      <NavBar />
      <NoticeComponents />
      <Footer />
    </div>
  );
};

export default NoticeBoard;
