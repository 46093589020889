import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { theme } from "../../../Style/theme";

const Caption1 = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Caption1};
  font-weight: ${(props) => props.theme.fontWeights.Caption1};
  line-height: ${(props) => props.theme.LineHeight.Caption1};
  font-family: "Pretendard";
  text-decoration: none;
  white-space: pre-line;
  color: #9c9c9c;
  margin-right: 370px;
`;

const Body1 = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Body1};
  font-weight: ${(props) => props.theme.fontWeights.Body1};
  line-height: ${(props) => props.theme.LineHeight.Body1};
  font-family: "Pretendard";
  text-decoration: none;
  white-space: pre-line;
  color: white;
  padding-top: 25px;
`;

const BigDiv = styled.div`
  width: 100%;
  background-color: #3f3f37;
`;

const Div = styled.div`
  margin: 0px auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 1440px;
  background-color: #3f3f37;
`;

const FooterContainer = styled.footer`
  background-color: #3f3f37;
  height: 244px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FooterWrapper1 = styled.div`
  margin-right: 710px;
  height: 36px;
  display: flex;
  width: 300px;
  /* background-color: yellow; */
  margin-bottom: 24px;
`;

const StuLogo = styled.img`
  height: 32px;
  width: 51px;
  margin-right: 40px;
`;

const IconBackground = styled.a`
  position: relative;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #9c9c9c;
  margin-right: 10px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Icon = styled.img`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
`;

const FooterWrapper2 = styled.div`
  margin-left: 20px;
  height: 56px;
  display: flex;
  width: 1040px;
  /* background-color: red;  */
  justify-content: flex-start;
  align-items: center;
`;

const HGULogo = styled.img`
  width: 60px;
  height: 56px;
  margin-right: 40px;
`;

const Footer = () => {
  return (
    <BigDiv>
      <Div>
        <ThemeProvider theme={theme}>
          <FooterContainer>
            <FooterWrapper1>
              <StuLogo
                src={require("../../../Assets/Img/Logo/FooterLogo.png")}
              />
              <IconBackground
                href="https://www.instagram.com/hgu_for_stu/"
                target="_blank"
              >
                <Icon
                  height={23}
                  width={23}
                  src={require("../../../Assets/Img/Logo/FooterInstagramLogo.png")}
                />
              </IconBackground>
              <IconBackground
                href="https://www.youtube.com/@28WITH/streams"
                target="_blank"
              >
                <Icon
                  height={15}
                  width={22}
                  src={require("../../../Assets/Img/Logo/FooterYouTubeLogo.png")}
                />
              </IconBackground>
              <IconBackground
                href="https://pf.kakao.com/_xfAunb"
                target="_blank"
              >
                <Icon
                  height={19}
                  width={19}
                  src={require("../../../Assets/Img/Logo/FooterKakaoTalkLogo.png")}
                />
              </IconBackground>
              <IconBackground
                href="https://map.naver.com/v5/entry/place/1816666857?lng=129.3881109&lat=36.1036575&placePath=%2Fhome%3Fentry=plt"
                target="_blank"
              >
                <Icon
                  height={20}
                  width={20}
                  src={require("../../../Assets/Img/Logo/FooterNaverLogo.png")}
                />
              </IconBackground>
            </FooterWrapper1>
            <FooterWrapper2>
              <HGULogo
                src={require("../../../Assets/Img/Logo/Footer_HGULogo.png")}
              />
              <Caption1>
                37554 경북 포항시 북구 흥해읍 한동로 558 한동대학교 <br />
                Tel: 054-260-1111 Fax: 054-260-1149
                <br />
                Copyright 2023. HANDONG GLOBAL UNIVERSITY All Rights Resesrved.
              </Caption1>
              <Body1>개인정보처리방침 | 이용약관</Body1>
            </FooterWrapper2>
          </FooterContainer>
        </ThemeProvider>
      </Div>
    </BigDiv>
  );
};

export default Footer;
