import React from 'react';
import ConvenienceFirst from '../Components/Web/ConvenienceInfo_Components/Web_ConvenienceFirst';
import Footer from '../Components/Web/Footer/Footer';
import NavBar from '../Components/Web/NavBar/Web_NavBar';

const ConvenienceInfo = () => {
  return (
    <div>
      <NavBar />
      <ConvenienceFirst />
      <Footer />
    </div>
  );
};

export default ConvenienceInfo;
