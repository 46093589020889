import React from "react";
import Footer from "../Components/Web/Footer/Footer";
import FloatingActionButton from "../Components/Web/Home_Components/Floatingbutton_Components";
import HomeCalendar from "../Components/Web/Home_Components/Web_HomeCalendar";
import HomeFirst from "../Components/Web/Home_Components/Web_HomeFirst";
import HomeNotice from "../Components/Web/Home_Components/Web_HomeNotice";
import HomeOfficeHour from "../Components/Web/Home_Components/Web_HomeOfficeHour";
import NavBar from "../Components/Web/NavBar/Web_NavBar";

const MainHome = () => {
  return (
    <div>
      <NavBar />
      <FloatingActionButton />
      <HomeFirst />
      <HomeNotice />
      <HomeCalendar />
      <HomeOfficeHour />
      <Footer />
    </div>
  );
};

export default MainHome;
