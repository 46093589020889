import React, { useState, useEffect, useCallback } from "react";
import styled, { ThemeProvider } from "styled-components";
import { theme } from "../../../Style/theme";
import DropDownArrow from "../../../Assets/Img/NoticeImg/DropDownIcon.png";
import { Link } from "react-router-dom";

const dummyData = [];
for (let i = 1; i <= 100; i++) {
  const inProgressValue = Math.floor(Math.random() * 3);
  dummyData.push({
    id: i,
    inProgress: inProgressValue,
    title: `Dummy Title 입니다 최대 몇 글자로 해야할까용 ㅋㅋ 메롱메롱 ${i}`,
    author: Math.floor(Math.random() * 100),
    date: "23/07/01",
  });
}

const Header1 = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Header1};
  font-weight: ${(props) => props.theme.fontWeights.Header1};
  line-height: ${(props) => props.theme.LineHeight.Header1};
  font-family: "Pretendard";
  text-decoration: none;
  color: #3f3f37;
  margin-right: 50px;
  margin-bottom: 30px;
  margin-top: 33px;
`;

const Body2 = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Body2};
  font-weight: ${(props) => props.theme.fontWeights.Body2};
  line-height: ${(props) => props.theme.LineHeight.Body2};
  font-family: "Pretendard";
  color: #3f3f37;
  text-decoration: none;
  margin-left: ${(props) => props.left}px;
  width: ${(props) => props.width}px;
  overflow: hidden; // 을 사용해 영역을 감출 것
  text-overflow: ellipsis; // 로 ... 을 만들기
  white-space: nowrap;
  /* background-color: red; */
`;

const Body2Div = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Body2};
  font-weight: ${(props) => props.theme.fontWeights.Body2};
  line-height: ${(props) => props.theme.LineHeight.Body2};
  font-family: "Pretendard";
  color: #3f3f37;
  text-decoration: none;
  margin-left: ${(props) => props.left}px;
  width: ${(props) => props.width}px;
  overflow: hidden; // 을 사용해 영역을 감출 것
  text-overflow: ellipsis; // 로 ... 을 만들기
  white-space: nowrap;
  /* background-color: red; */
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Body4 = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Body4};
  font-weight: ${(props) => props.theme.fontWeights.Body4};
  line-height: ${(props) => props.theme.LineHeight.Body4};
  font-family: "Pretendard";
  text-decoration: none;
  color: #3f3f37;
  width: ${(props) => props.width}px;
  margin-right: ${(props) => props.right}px;
  margin-top: ${(props) => props.top}px;
  margin-left: ${(props) => (props.itemID >= 10 ? 12 : props.left)}px;
  overflow: hidden; // 을 사용해 영역을 감출 것
  text-overflow: ellipsis; // 로 ... 을 만들기
  white-space: nowrap;
  /* background-color: yellow; */
`;

const Body4Div = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Body4};
  font-weight: ${(props) => props.theme.fontWeights.Body4};
  line-height: ${(props) => props.theme.LineHeight.Body4};
  font-family: "Pretendard";
  text-decoration: none;
  color: #3f3f37;
  margin-right: ${(props) => props.right}px;
  margin-top: ${(props) => props.top}px;
  margin-left: ${(props) => (props.itemID >= 10 ? 12 : props.left)}px;
  width: 40px;
  /* background-color: #3f3f37; */
`;

const Caption4 = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Caption4};
  font-weight: ${(props) => props.theme.fontWeights.Caption4};
  line-height: ${(props) => props.theme.LineHeight.Caption4};
  font-family: "Pretendard";
  color: #cfd4d9;
  margin-top: 160px;
  margin-bottom: 8px;
`;

const RightDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 826px;
  height: ${(props) => (props.color === "0" ? "1350px" : "1350px")};
  /* background-color: blue; */
`;

const SearchInputDiv = styled.div`
  width: 826px;
  /* background-color: green; */
  height: 36px;
  display: flex;
  justify-content: flex-end;
  margin-top: 27px;
  margin-right: 43px;
`;

const DropdownButton = styled.select`
  width: 100px;
  height: 36px;
  justify-content: center;
  align-items: center;
  display: flex;
  padding-left: 8px;
  border: 1px solid #d0d0d0;
  border-right: none;
  align-items: center;
  font-size: 16px;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: ${(props) => (props.value ? "black" : "#d0d0d0")};
  appearance: none; /* Hide the default arrow */
  -webkit-appearance: none; /* For Safari */
  -moz-appearance: none; /* For Firefox */
  background-image: url(${DropDownArrow});
  background-repeat: no-repeat;
  background-position: 82px 13px;
  background-size: 12px 10px;
  outline: none;
  cursor: pointer;
`;

const SearchInput = styled.input`
  width: 165px;
  padding: 8px 56px 8px 8px;
  border: 1px solid #d0d0d0;
  border-right: none;
  color: #3f3f37;
  font-size: 16px;
  font-family: "Pretendard";
  line-height: 20px;
  outline: none;
  &::placeholder {
    color: #d0d0d0;
  }
  &:focus::placeholder {
    color: #3f3f37;
  }
`;

const SearchInputButton = styled.button`
  width: 36px;
  height: 36px;
  background-color: #3f3f37;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const SearchIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const HRThick = styled.div`
  position: absolute;
  width: 826px;
  height: 0px;
  display: flex;
  margin-top: 95px;
  border: 2px solid #143551;
`;

const PageTitleDiv = styled.div`
  width: 828px;
  height: 36px;
  /* background-color: green; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 0.5px solid #143551;
`;

const PageButtonDiv = styled.div`
  width: 350px;
  height: 32px;
  /* background-color: green; */
  margin-top: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FirstArrowButton = styled.button`
  width: 32px;
  height: 32px;
  border: 1px solid #9c9c9c;
  border-right: ${(props) => props.border}px solid #9c9c9c;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${(props) => props.right}px;
  cursor: pointer;
`;

const PageButton = styled.div`
  font-size: 16px;
  font-family: "Pretendard";
  font-weight: 600;
  line-height: 20px;
  color: ${(props) => (props.isActive ? "#3F3F37" : "#D0D0D0")};
  width: 10px;
  height: 20px;
  margin-left: 32px;
  cursor: pointer;
`;

const BeforeButton = styled.button`
  width: 32px;
  height: 32px;
  border: 1px solid #9c9c9c;
  border-left: 0.5px solid #9c9c9c;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -8px;
  cursor: pointer;
`;

const SingleArrow = styled.img`
  width: 6.5px;
  height: 8px;
`;

const PageArrowButton = styled.button`
  width: 32px;
  height: 32px;
  border: 1px solid #9c9c9c;
  border-right: 0.5px solid #9c9c9c;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 24px;
  cursor: pointer;
`;

const DoubleArrow = styled.img`
  width: 12.5px;
  height: 8px;
`;

const LastArrowButton = styled.button`
  width: 32px;
  height: 32px;
  border: 1px solid #9c9c9c;
  border-left: 0.5px solid #9c9c9c;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const NoContentsImg = styled.img`
  width: 80px;
  height: 80px;
`;

const ButtonDiv = styled.div`
  width: 826px;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: -20px;
`;

const Stugov = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchItemSetting, setSearchItemSetting] = useState("");
  const [selectedCategorySetting, setSelectedCategorysetting] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const itemsPerPage = 15;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const [totalPages, setTotalPages] = useState(0);
  const filteredData = dummyData
    .filter((item) => {
      if (selectedCategory === "") {
        return item.title.toLowerCase().includes(searchTerm.toLowerCase());
      } else {
        return item[selectedCategory.toLowerCase()]
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      }
    })
    .slice(startIndex, endIndex);

  useEffect(() => {
    const filteredData = dummyData.filter((item) => {
      if (selectedCategory === "") {
        return item.title.toLowerCase().includes(searchTerm.toLowerCase());
      } else {
        return item[selectedCategory.toLowerCase()]
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      }
    });

    setTotalPages(Math.ceil(filteredData.length / itemsPerPage));
  }, [searchTerm, selectedCategory]);

  const handleCategoryChange = (event) => {
    setSelectedCategorysetting(event.target.value);
  };

  const handleSearch = (event) => {
    setSearchItemSetting(event.target.value);
  };

  const handleSearchButton = (event) => {
    setSearchTerm(searchItemSetting);
    setSelectedCategory(selectedCategorySetting);
    setCurrentPage(1);
    window.scrollTo(0, 0);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
    window.scrollTo(0, 0);
  };

  const handleNextPage = () => {
    const nextPage = Math.ceil(currentPage / 5) * 5 + 1;
    if (nextPage <= totalPages) {
      setCurrentPage(nextPage);
      window.scrollTo(0, 0);
    } else {
      setCurrentPage(totalPages);
      window.scrollTo(0, 0);
    }
  };

  const handleBeforePage = () => {
    if (currentPage > 1) {
      const currentRangeStart = Math.floor((currentPage - 1) / 5) * 5 + 1;
      setCurrentPage(currentRangeStart - 5);
      window.scrollTo(0, 0);
    }
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
    window.scrollTo(0, 0);
  };

  const BoxComponent = ({ data }) => {
    const exportTxt = useCallback(() => {
      let fileName = "파일이름.txt";
      let output = "string 타입의 데이터";
      const element = document.createElement("a");
      const file = new Blob([output], {
        type: "text/plain",
      });
      element.href = URL.createObjectURL(file);
      element.download = fileName;
      document.body.appendChild(element); // FireFox
      element.click();
    }, []);

    const BoxGrid = styled.div`
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      text-decoration: none;
    `;

    const BoxItem = styled(Link)`
      border: 0.5px solid black;
      display: flex;
      align-items: center;
      border-left: none;
      border-right: none;
      text-decoration: none;
      width: 750px;
      height: 52px;
    `;

    const BoxItemDiv = styled.div`
      border: 0.5px solid black;
      display: flex;
      align-items: center;
      border-left: none;
      border-right: none;
      text-decoration: none;
      width: 828px;
      height: 52px;
    `;

    const ImgButton = styled.button`
      width: 24px;
      height: 22px;
      margin-left: ${(props) => props.left}px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ffffff;
      cursor: pointer;
      border: none;
      padding-top: 6px;
    `;

    const DownloadImg = styled.img`
      width: 24px;
      height: 24px;
    `;

    return (
      <BoxGrid>
        {data.map((item) => (
          <BoxItemDiv>
            <BoxItem to={`/notice/${item.id}`} key={item.id}>
              {" "}
              <Body4Div left={12} itemID={item.id}>
                {item.id}
              </Body4Div>
              <Body4 width={500} left={20}>
                {item.title}
              </Body4>
              <Body2Div width={50} left={45}>
                {item.author}
              </Body2Div>
              <Body2Div width={60} left={10}>
                {item.date}
              </Body2Div>
            </BoxItem>
            <ImgButton left={14} onClick={() => exportTxt()}>
              <DownloadImg
                src={require("../../../Assets/Img/Resource_img/FileDownload.png")}
              />
            </ImgButton>
          </BoxItemDiv>
        ))}
      </BoxGrid>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <RightDiv>
        <Header1>총학생회</Header1>
        <HRThick />
        <PageTitleDiv>
          <Body2 left={5}>번호</Body2>
          <Body2 left={216}>제목</Body2>
          <Body2 left={360}>조회</Body2>
          <Body2 left={39}>날짜</Body2>
          <Body2 left={35}>파일 다운</Body2>
        </PageTitleDiv>
        <>
          <BoxComponent data={filteredData} />
          <ButtonDiv>
            <SearchInputDiv>
              <DropdownButton
                value={selectedCategory}
                onChange={handleCategoryChange}
              >
                <option value="title">제목</option>
                <option value="date">내용</option>
                <option value="author">작성자</option>
              </DropdownButton>
              <SearchInput
                type="text"
                placeholder="검색어를 입력해 주세요."
                value={searchItemSetting}
                onChange={handleSearch}
              />
              <SearchInputButton onClick={handleSearchButton}>
                <SearchIcon
                  src={require("../../../Assets/Img/NoticeImg/SearchIcon.png")}
                />
              </SearchInputButton>
            </SearchInputDiv>
          </ButtonDiv>
          {totalPages === 0 ? (
            <>
              <Caption4>아직 컨텐츠가 없습니다.</Caption4>
              <NoContentsImg
                src={require("../../../Assets/Img/NoticeImg/NoContentsImg.png")}
              />
            </>
          ) : (
            <PageButtonDiv>
              {currentPage === 1 ? (
                <></>
              ) : totalPages < 5 ? (
                <></>
              ) : currentPage > 5 ? (
                <>
                  <FirstArrowButton
                    border={0.5}
                    right={0}
                    onClick={handleFirstPage}
                    disabled={currentPage === 1}
                  >
                    <DoubleArrow
                      src={require("../../../Assets/Img/ConvenienceInfo_Img/HGUShop/MoveFirstArrow.png")}
                    />
                  </FirstArrowButton>
                  <BeforeButton
                    onClick={handleBeforePage}
                    disabled={currentPage === 1}
                  >
                    <SingleArrow
                      src={require("../../../Assets/Img/ConvenienceInfo_Img/HGUShop/BackArrow.png")}
                    />
                  </BeforeButton>
                </>
              ) : (
                <FirstArrowButton
                  border={1}
                  right={-8}
                  onClick={handleFirstPage}
                  disabled={currentPage === 1}
                >
                  <DoubleArrow
                    src={require("../../../Assets/Img/ConvenienceInfo_Img/HGUShop/MoveFirstArrow.png")}
                  />
                </FirstArrowButton>
              )}
              {Array.from({ length: totalPages }, (_, index) => index + 1)
                .filter(
                  (pageNumber) =>
                    pageNumber >= (Math.ceil(currentPage / 5) - 1) * 5 + 1 &&
                    pageNumber <= (Math.ceil(currentPage / 5) - 1) * 5 + 5
                )
                .map((pageNumber) => (
                  <PageButton
                    key={pageNumber}
                    onClick={() => handlePageChange(pageNumber)}
                    disabled={pageNumber === currentPage}
                    isActive={pageNumber === currentPage}
                  >
                    {pageNumber}
                  </PageButton>
                ))}
              {currentPage === totalPages ? (
                <></>
              ) : totalPages < 5 ? (
                <></>
              ) : (
                <>
                  <PageArrowButton
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    <SingleArrow
                      src={require("../../../Assets/Img/ConvenienceInfo_Img/HGUShop/NextArrow.png")}
                    />
                  </PageArrowButton>
                  <LastArrowButton
                    onClick={handleLastPage}
                    disabled={currentPage === totalPages}
                  >
                    <DoubleArrow
                      src={require("../../../Assets/Img/ConvenienceInfo_Img/HGUShop/MoveLastArrow.png")}
                    />
                  </LastArrowButton>
                </>
              )}
            </PageButtonDiv>
          )}
        </>
      </RightDiv>
    </ThemeProvider>
  );
};

export default Stugov;
