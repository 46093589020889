import React from "react";
import { useParams } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import { theme } from "../../../Style/theme";
import StuCouncilSecond from "./Web_StuCouncilSecond";

const Header1 = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Header1};
  font-weight: ${(props) => props.theme.fontWeights.Header1};
  line-height: ${(props) => props.theme.LineHeight.Header1};
  font-family: "Pretendard";
  text-decoration: none;
  margin-right: 50px;
  color: #3f3f37;
`;

const Header4 = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Header4};
  font-weight: ${(props) => props.theme.fontWeights.Header4};
  line-height: ${(props) => props.theme.LineHeight.Header4};
  font-family: "Pretendard";
  text-decoration: none;
  width: 745px;
  color: #143551;
  margin-top: 64px;
`;

const Body6 = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Body6};
  font-weight: ${(props) => props.theme.fontWeights.Body6};
  line-height: ${(props) => props.theme.LineHeight.Body6};
  font-family: "Pretendard";
  text-decoration: none;
  width: 745px;
  color: #9c9c9c;
  margin-top: 48px;
`;

const RightDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 790px;
  height: ${(props) => (props.color === "0" ? "1000px" : "2000px")};
  /* background-color: red; */
`;

const HR = styled.div`
  width: 745px;
  height: 0px;
  left: 0px;
  margin-top: 56px;
  border: 2px solid #143551;
`;
const PesidencyImg = styled.img`
  width: 601px;
  height: 479px;
  margin-top: 40px;
  margin-right: 145px;
`;

const StuCouncilImg = styled.img`
  width: 655px;
  height: 437px;
  margin-top: 40px;
  margin-right: 90px;
`;

const StuCouncilCopmpnents = () => {
  const { id } = useParams();

  return (
    <ThemeProvider theme={theme}>
      {id === "greet" ? (
        <RightDiv>
          <Header1>인사말</Header1>
          <HR />
          <PesidencyImg
            src={require("../../../Assets/Img/StudentCouncil_Img/presidency.png")}
          />
          <Header4>2023년 제28대 총학생회 'WITH'</Header4>
          <Body6>
            사랑하는 한동 공동체 구성원 모두에게 첫 인사를 드립니다.
            <br />
            안녕하세요, 하나님의 대학 한동대학교의 제28대 총학생회
            중앙집행위원회 ‘WITH’입니다.
            <br />
            <br />
            먼저, 새해 첫 날 한동대학교 총학생회의 이름으로 여러분께 인사를 드릴
            수 있음에 하나님께 큰 감사와 영광을 <br />
            올려드립니다.
            <br />
            <br />
            뜨거웠던 한동의 2022년은 저물고, 2023년 새해가 밝았습니다.
            <br />
            2022년도 한동의 첫 시작은 많은 변화들이 있었으며,
            <br />
            코로나로 인한 여러 제약들이 있었음에도 한동은 여전히 한동다움을 잃지
            않았습니다.
            <br />
            <br />
            2022년도 한동의 끝자락은 유독 추웠지만,
            <br />
            한동 고유의 가치와 문화가 되살아나 모두가 함께 울고 웃으며,
            ‘하나됨’을 뜨겁게 느낄 수 있었습니다.
            <br />
            <br />
            2023년, 총학생회 ‘WITH’는 ‘함께함’의 가치를 더욱 소망합니다.
            <br />
            1월 1일, 오늘을 기점으로 총학생회 ‘WITH’의 공식 임기가
            시작되었습니다.
            <br />
            2023년의 총학생회 WITH는 2024년의 해가 완전히 떠오르기 전까지,
            <br />
            지난 11월부터 외쳤던 ‘with God, with You, with Handong’이라는
            슬로건과 함께하겠습니다.
            <br />
            <br />
            “우리가 시작할 때에 확신한 것을 끝까지 견고히 잡고 있으면
            <br />
            그리스도와 함께 참여한 자가 되리라” (히3:14)
            <br />
            이 말씀의 ‘시작할 때’와 ‘끝까지’는 예수님을 처음 믿은 순간부터
            마지막 까지를 의미합니다.
            <br />
            ‘확신한 것’은 우리에게 주신 복음이며 이것을 견고히 잡기 위해서는
            그리스도와 함께 참여한 자가 되어야 합니다.
            <br />
            영원히 우리와 함께하시는 하나님과의 함께함은 총학생회 WITH 정체성의
            근간이 되어 한동의 학우님들을,
            <br />
            또 한동의 모든 일들을 마주하고자 합니다.
            <br />
            제28대 총학생회 WITH는 ‘그리스도와 함께함’을 본질로 삼아, 앞으로의
            모든 여정 속에 하나님과 동행하겠습니다.
            <br />
            <br />
            하나님의 대학, 한동대학교라는 정체성을 잃지 않도록 끊임없이 함께
            고민하고 기도하는 총학생회가 되겠습니다.
            <br />
            세상의 빛과 소금의 역할을 감당하며, ‘Why not change the world’를
            외치는 여러분들과 함께 나아가는 총학생회가 되겠습니다.
            <br />
            부족한 역량들을 훈련하며 배워서 남 주기 위해 모인 이 곳, 한동과 함께
            성장하는 총학생회가 되겠습니다.
            <br />
            <br />
            끝으로, 지난 1년간 보이지 않는 섬김과 헌신으로, 한동의 하나됨을 위해
            힘써온,
            <br />
            제27대 총학생회 ‘So One (소원)’ 구성원 여러분에게 진심으로
            감사드립니다.
            <br />
            <br />
            노찬우 총학생회장님, 장빛나 부총학생회장님. 그리고
            <br />
            <br />
            길로아, 김성주, 김수빈, 김수형, 김승환, 김어진, 김윤성, 김윤아,
            김재희, 김정원, 김주성, 김찬영, 김필겸, 김하진, 문창대, 박상준,
            박서윤, 박소은, 박채은, 배여호수아, 손현건, 송영은, 송준, 송한결,
            안주원, 안준상, 오죠이, 육지영, 윤세훈, <br />
            이가은, 이예은, 이찬, 이채원, 이현서, 이희라, 인찬영, 임청현,
            정은비, 정은빈, 최예성, 최하은, 한주희, 허예은, 홍주은
            <br />
            <br />
            이상 46명의 구성원 한 분, 한 분의 이름을 기억하겠습니다.
            <br />
            여러분의 눈물과 땀방울이 있었기에, 지난 해의 한동이 더욱 아름답게
            빛날 수 있었습니다.
            <br />
            이제는 저희가 여러분의 뒤를 이어, 겸손함으로 한동을 섬기는
            총학생회가 되겠습니다.
            <br />
            <br />
            <br />
            감사하고, 또 감사합니다. 2023년 1월 1일
            <br />
            <br />
            With God, 하나님 앞에서 “겸손”하게,
            <br />
            With You, 한동의 학우님들 곁에서 “섬김”으로,
            <br />
            With Handong, 한동 안팎에서 더욱 “사랑”하는 마음을 가지며,
            <br />
            <br />
            한동대학교 제28대 총학생회장 김철규, 부총학생회장 임다혜 올림.
          </Body6>
          <StuCouncilImg
            src={require("../../../Assets/Img/StudentCouncil_Img/StuCouncilImg.png")}
          />
        </RightDiv>
      ) : id === "department" ? (
        <StuCouncilSecond />
      ) : (
        <></>
      )}
    </ThemeProvider>
  );
};

export default StuCouncilCopmpnents;
