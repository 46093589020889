import { Link, Outlet } from "react-router-dom";
import React, { useState, useEffect } from "react";
import styled, { ThemeProvider } from "styled-components";
import { theme } from "../../../Style/theme";
import { useParams } from "react-router-dom";

const Body1 = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Body1};
  font-weight: ${(props) => (props.color === "0" ? "600" : "400")};
  line-height: ${(props) => props.theme.LineHeight.Body1};
  font-family: "Pretendard";
  text-decoration: none;
  color: ${(props) => (props.color === "0" ? "#FFFFFF" : "#3F3F37")};
`;

const BodyText1 = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Body1};
  font-weight: ${(props) => (props.color === "1" ? "600" : "400")};
  line-height: ${(props) => props.theme.LineHeight.Body1};
  font-family: "Pretendard";
  text-decoration: none;
  color: ${(props) => (props.color === "0" ? "#3F3F37" : "#FFFFFF")};
`;

const Header3 = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Header3};
  font-weight: ${(props) => props.theme.fontWeights.Header3};
  line-height: ${(props) => props.theme.LineHeight.Header3};
  font-family: "Pretendard";
  text-decoration: none;
  color: #3f3f37;
  margin-bottom: 32px;
`;

const Div = styled.div`
  margin: 0px auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 100%;
`;

const PartDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: ${(props) => (props.color === "1" ? "1200px" : "2750px")};
  align-items: center;
  margin: 0px auto;
  padding-right: 5px;
  margin-top: 100px;
`;

const TitleDiv = styled.div`
  display: flex;
  height: 38px;
  justify-content: start;
  width: 100%;
  align-items: center;
  /* background-color: green; */
  margin-bottom: 56px;
`;

const HRLight = styled.div`
  position: absolute;
  width: 99.9vw;
  height: 0px;
  left: 0px;
  margin-top: 40px;
  border: 0.8px solid #d0d0d0;
  overflow-x: hidden;
`;

const Home = styled.img`
  width: 16px;
  height: 16px;
  margin-left: 2px;
`;

const RightAllow = styled.img`
  width: 5px;
  height: 9px;
  margin-left: 8px;
`;

const WhereText = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  font-family: "Pretendard";
  text-decoration: none;
  color: #3f3f37;
  margin-left: 8px;
`;

const MainDiv = styled.div`
  display: flex;
  height: 200px;
  justify-content: center;
  width: 100%;
  /* background-color: blue; */
`;

const LeftDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 190px;
  height: 156px;
  /* background-color: yellow; */
  margin-right: 108px;
  margin-top: 35px;
`;

const LeftDivButton1 = styled.button`
  width: 190px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: ${(props) => (props.color === "0" ? "#143551" : "#FFFFFF")};
  border: 1px solid rgba(63, 63, 55, 1); /* 노랑색 테두리 */
  border-left: none; /* 좌측 테두리 제거 */
  border-right: none; /* 우측 테두리 제거 */
  cursor: pointer;
`;

const LeftDivButton2 = styled.button`
  width: 190px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: ${(props) => (props.color === "0" ? "#FFFFFF" : "#143551")};
  border: 1px solid rgba(63, 63, 55, 1); /* 노랑색 테두리 */
  border-left: none; /* 좌측 테두리 제거 */
  border-right: none; /* 우측 테두리 제거 */
  cursor: pointer;
`;

const NavItem = styled(Link)`
  text-decoration: none;
`;

const StuCouncilFirst = () => {
  const [isGreetings, setIsGreetings] = useState("0"); // 인사 UI
  const { id } = useParams();

  useEffect(() => {
    if (id === "greet") {
      setIsGreetings("0");
    } else if (id === "department") {
      setIsGreetings("1");
    }
  }, [id]);

  const handleClickGreetings = () => {
    setIsGreetings("0");
  };

  const handleClickorganization = () => {
    setIsGreetings("1");
  };

  return (
    <Div>
      <ThemeProvider theme={theme}>
        <PartDiv color={isGreetings}>
          <TitleDiv>
            <Home
              src={require("../../../Assets/Img/StudentCouncil_Img/HomeIcon.png")}
            />
            <RightAllow
              src={require("../../../Assets/Img/StudentCouncil_Img/MiniRightIcon.png")}
            />
            <WhereText>총학생회</WhereText>
            <RightAllow
              src={require("../../../Assets/Img/StudentCouncil_Img/MiniRightIcon.png")}
            />
            {isGreetings === 0 ? (
              <WhereText>인사말</WhereText>
            ) : (
              <WhereText>조직도</WhereText>
            )}
          </TitleDiv>
          <HRLight />
          <MainDiv>
            <LeftDiv>
              <Header3>총학생회</Header3>
              <NavItem to="/about/greet">
                <LeftDivButton1
                  onClick={handleClickGreetings}
                  color={isGreetings}
                >
                  <Body1 color={isGreetings}>인사말</Body1>
                </LeftDivButton1>
              </NavItem>
              <NavItem to="/about/department">
                <LeftDivButton2
                  onClick={handleClickorganization}
                  color={isGreetings}
                >
                  <BodyText1 color={isGreetings}>조직도</BodyText1>
                </LeftDivButton2>
              </NavItem>
            </LeftDiv>
            <Outlet />
          </MainDiv>
        </PartDiv>
      </ThemeProvider>
    </Div>
  );
};

export default StuCouncilFirst;
