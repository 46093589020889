import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const FloatingActionButtonComponets = styled.button`
  width: 48px;
  height: 48px;
  padding: 10px;
  z-index: 999;
  position: fixed;
  top: 600px;
  /* left: 1600px; */
  /* margin-right: 2px; */
  margin-left: 1400px;
  border-radius: 50px;
  background: var(--white, #FFF);
  box-shadow: 0px 0px 8px 0px rgba(20, 53, 81, 0.25); 
  border: none;
  cursor: pointer;
  opacity: ${props => props.isVisible ? 1 : 0};
  transition: opacity 0.3s ease-in-out;
`;

const TopArrow = styled.img`
  width: 15px;
  height: 10px;
  margin: 0;
  margin-bottom: 4px;
`;

const TopText = styled.p`
  font-size: 12px;
  font-family: 'Noto Sans KR';
  font-weight: 700;
  color: rgba(20, 53, 81, 1);
  margin: 0;
`;

const Div = styled.div`
  margin: 0px auto;
  width: 1440px;
`;

const FloatingActionButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleButtonClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleScroll = () => {
    const scrollTop = window.pageYOffset;
    if (scrollTop >= 400) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Div>
      <FloatingActionButtonComponets onClick={handleButtonClick} isVisible={isVisible}>
        <TopArrow src={require('../../../Assets/Img/MainHome_Img/FloatingButtonArrow.png')} />
        <TopText>TOP</TopText>
      </FloatingActionButtonComponets>
    </Div>
  );
};

export default FloatingActionButton;
