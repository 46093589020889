import { useParams } from "react-router-dom";
// import styled, { ThemeProvider } from "styled-components";
// import { theme } from "../../../Style/theme";
import React, { useEffect } from "react";

const HguShopDetail = () => {
  const { id } = useParams();
  useEffect(() => {
    console.log(id);
  }, [id]);

  return (
    <div>
      <h1>HguShopDetail Page</h1>
      <p>Welcome to the Student Council page!</p>
    </div>
  );
};

export default HguShopDetail;
