import { useParams } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import { theme } from "../../../Style/theme";
import React, { useEffect, useCallback } from "react";
import NavBar from "../NavBar/Web_NavBar";
import Footer from "../Footer/Footer";

const Header1 = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Header1};
  font-weight: ${(props) => props.theme.fontWeights.Header1};
  line-height: ${(props) => props.theme.LineHeight.Header1};
  font-family: "Pretendard";
  text-decoration: none;
  color: #3f3f37;
  margin-bottom: 110px;
`;

const Header2 = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Header2};
  font-weight: ${(props) => props.theme.fontWeights.Header2};
  line-height: ${(props) => props.theme.LineHeight.Header2};
  font-family: "Pretendard";
  text-decoration: none;
  color: #3f3f37;
  margin-bottom: 12px;
`;

const Body4 = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Body4};
  font-weight: ${(props) => props.theme.fontWeights.Body4};
  line-height: ${(props) => props.theme.LineHeight.Body4};
  font-family: "Pretendard";
  color: Black;
`;

const Body5 = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Body5};
  font-weight: ${(props) => props.theme.fontWeights.Body5};
  line-height: ${(props) => props.theme.LineHeight.Body5};
  font-family: "Pretendard";
  text-decoration: none;
  color: #9c9c9c;
`;

const Div = styled.div`
  margin: 0px auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 100%;
`;

const PartDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 1400px;
  width: 1047px;
  align-items: center;
  margin: 0px auto;
  margin-top: 100px;
  background-color: gray;
`;

const TitleDiv = styled.div`
  display: flex;
  height: 38px;
  justify-content: start;
  width: 100%;
  align-items: center;
  /* background-color: green; */
  margin-bottom: 42px;
`;

const Home = styled.img`
  width: 16px;
  height: 16px;
  margin-left: 2px;
`;

const RightAllow = styled.img`
  width: 5px;
  height: 9px;
  margin-left: 8px;
`;

const WhereText = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  font-family: "Pretendard";
  text-decoration: none;
  color: #3f3f37;
  margin-left: 8px;
`;

const MainDiv = styled.div`
  width: 100%;
  height: 335px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: red;
`;

const HRLight = styled.div`
  position: absolute;
  width: 99.9vw;
  height: 0px;
  left: 0px;
  margin-top: 40px;
  border: 0.8px solid #d0d0d0;
  overflow-x: hidden;
`;

const HRThick = styled.div`
  position: absolute;
  width: 1040px;
  height: 0px;
  display: flex;
  margin-top: ${(props) => props.top}px;
  border: 3px solid #143551;
`;

const ContentsDiv = styled.div`
  width: 1047px;
  height: 54px;
  /* background-color: blue; */
  display: flex;
  flex-direction: column;
  padding-left: 3px;
`;

const HRSeparator = styled.div`
  width: 1040px;
  border: 1px solid #d0d0d0;
  margin-top: 24px;
`;

const DownloadButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 536px;
  height: 50px;
  background-color: #ffffff;
  border: 1px solid var(--stroke-gray, #d0d0d0);
  margin-top: 56px;
  margin-right: 511px;
  cursor: pointer;
`;

const DownloadImg = styled.img`
  width: 14px;
  height: 12.759px;
  margin-right: 14px;
`;

const DownloadArrowImg = styled.img`
  width: 10.501px;
  height: 15.949px;
  margin-left: 390px;
`;

const BodyDiv = styled.div`
  width: 1047px;
  height: 822px;
  background-color: antiquewhite;
`;

const BottomDiv = styled.div`
  width: 1047px;
  height: 633px;
  background-color: aquamarine;
`;

const NoticeDetail = () => {
  const { id } = useParams();
  useEffect(() => {
    console.log(id);
  }, [id]);

  const exportTxt = useCallback(() => {
    let fileName = "파일이름.txt";
    let output = "string 타입의 데이터";
    const element = document.createElement("a");
    const file = new Blob([output], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = fileName;
    document.body.appendChild(element); // FireFox
    element.click();
  }, []);

  return (
    <>
      <NavBar />
      <Div>
        <ThemeProvider theme={theme}>
          <PartDiv>
            <TitleDiv>
              <Home
                src={require("../../../Assets/Img/StudentCouncil_Img/HomeIcon.png")}
              />
              <RightAllow
                src={require("../../../Assets/Img/StudentCouncil_Img/MiniRightIcon.png")}
              />
              <WhereText>공지사항</WhereText>
            </TitleDiv>
            <HRLight />
            <MainDiv>
              <Header1>공지사항</Header1>
              <HRThick top={115} />
              <ContentsDiv>
                <Header2>이 페이지 id값은 '{id}' 입니다.</Header2>
                <Body5>글쓴이 | 조회수 OOO | YYYY-MM-DD</Body5>
              </ContentsDiv>
              <HRSeparator />
              <DownloadButton onClick={() => exportTxt()}>
                <DownloadImg
                  src={require("../../../Assets/Img/NoticeImg/DownloadImg.png")}
                />
                <Body4>첨부파일 1</Body4>
                <DownloadArrowImg
                  src={require("../../../Assets/Img/NoticeImg/DownLoadArrowImg.png")}
                />
              </DownloadButton>
            </MainDiv>
            <BodyDiv>공지 내용 뜨는 곳</BodyDiv>
            <HRThick top={965} />
            <BottomDiv>목록 넣는 곳</BottomDiv>
          </PartDiv>
        </ThemeProvider>
      </Div>
      <Footer />
    </>
  );
};

export default NoticeDetail;
