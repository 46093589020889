import React, { useState, useEffect } from "react";
import styled from "styled-components";
import HeroBanner1 from "../../../Assets/Img/MainHome_Img/Home_HeroBanner_Img/HeroBanner_1.png";
import HeroBanner2 from "../../../Assets/Img//MainHome_Img/Home_HeroBanner_Img/HeroBanner_2.png";
import HeroBanner3 from "../../../Assets/Img//MainHome_Img/Home_HeroBanner_Img/HeroBanner_1.png";

const CarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 99px;
  /* width: 100%; */
`;

const Div = styled.div`
  margin: 0 auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-content: center;
  min-width: 1500px;
  width: 100vw;
`;

const PartDiv = styled.div`
  width: 100%;
`;

const CarouselImage = styled.img`
  width: 100%;
  height: 370px;
`;

const ArrowContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ArrowButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin-top: 160px;
  margin-left: ${(props) => (props.left === 1 ? "0px" : "700px")};
  margin-right: ${(props) => (props.left === 1 ? "700px" : "0px")};
  cursor: pointer;
`;

const ArrowImage = styled.img`
  width: 22px;
  height: 45px;
`;

const IndicatorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 90%;
  left: 0;
  right: 0;
  margin-top: 5px;
`;

const IndicatorButton = styled.button`
  background: ${(props) =>
    props.active === props.ind ? "#FFFFFF" : "rgba(0,0,0,0)"};
  border: 1px solid #3f3f37; /* Add border style */
  width: 10px;
  height: 14px;
  border-radius: 50%;
  margin: 5px;
  cursor: pointer;
`;

const HomeFirst = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const images = [HeroBanner1, HeroBanner2, HeroBanner3, HeroBanner2];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [images.length]);

  const goToPreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <Div>
      <PartDiv>
        <CarouselContainer>
          <CarouselImage src={images[currentImageIndex]} alt="Carousel" />
          <ArrowContainer>
            <ArrowButton left={1} onClick={goToPreviousImage}>
              <ArrowImage
                src={require("../../../Assets/Img/MainHome_Img/Home_HeroBanner_Img/Left_Arrow.png")}
                alt="Previous"
              />
            </ArrowButton>
            <ArrowButton left={0} onClick={goToNextImage}>
              <ArrowImage
                src={require("../../../Assets/Img/MainHome_Img/Home_HeroBanner_Img/Right_Arrow.png")}
                alt="Next"
              />
            </ArrowButton>
          </ArrowContainer>
          <IndicatorContainer>
            {images.map((_, index) => (
              <IndicatorButton
                key={index}
                active={currentImageIndex}
                ind={index}
              />
            ))}
          </IndicatorContainer>
        </CarouselContainer>
      </PartDiv>
    </Div>
  );
};

export default HomeFirst;
