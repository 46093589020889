import { useParams } from "react-router-dom";
import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { theme } from "../../../Style/theme";
import ConvenienceSecond from "./Web_ConvenienceSecond";
import YourComponent from "./Web_HGUShop_components";

const Header1 = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Header1};
  font-weight: ${(props) => props.theme.fontWeights.Header1};
  line-height: ${(props) => props.theme.LineHeight.Header1};
  font-family: "Pretendard";
  text-decoration: none;
  margin-right: 50px;
  color: #3f3f37;
`;

const RightDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 745px;
  /* background-color: red; */
`;

function ConvenienceInfoComponents() {
  const { id } = useParams();
  return (
    <ThemeProvider theme={theme}>
      {id === "hgushop" ? (
        <RightDiv>
          <Header1>HGU SHOP</Header1>
          <YourComponent />
        </RightDiv>
      ) : id === "rentlist" ? (
        <ConvenienceSecond />
      ) : (
        <></>
      )}
    </ThemeProvider>
  );
}

export default ConvenienceInfoComponents;
