import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { theme } from "../../../Style/theme";
import { Link } from "react-router-dom";

const Header1 = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Header1};
  font-weight: ${(props) => props.theme.fontWeights.Header1};
  line-height: ${(props) => props.theme.LineHeight.Header1};
  font-family: "Pretendard";
  text-decoration: none;
  color: #3f3f37;
  margin-left: ${(props) => props.left}px;
`;

const Caption1 = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Caption1};
  font-weight: ${(props) => props.theme.fontWeights.Caption1};
  line-height: ${(props) => props.theme.LineHeight.Caption1};
  font-family: "Pretendard";
  text-decoration: none;
  white-space: pre-line;
  color: #3f3f37;
  margin-left: 36px;
`;

const SemiTitle = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: rgba(63, 63, 55, 1);
  margin-right: ${(props) => props.right}px;
`;

const SemiBody = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #3f3f37;
`;

const Div = styled.div`
  margin: 0px auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 1440px;
`;

const PartDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 900px;
  align-items: center;
  margin: 0px auto;
`;

const TitleDiv = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  width: 100%;
  margin-top: 80px;
`;

const CustomButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 4px;
  width: 68px;
  height: 30px;
  background: #143551;
  border-radius: 40px;
  flex: none;
  order: 1;
  flex-grow: 0;
  color: white;
  border: none; /* 테두리 제거 */
  cursor: pointer;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  margin-left: 200px;

  .Arrow {
    padding-bottom: 1.5px;
  }
`;

const BodyDiv = styled.div`
  display: flex;
  height: 488px;
  width: 100%;
  /* background-color: blue; //화면 파랑색 배경 */
  margin-top: 24px;
`;

const TableDiv = styled.div`
  height: 488px;
  width: 566px;
  /* background-color: red; // 화면 빨간색 배경 */
`;

const HGUBackLogo = styled.img`
  position: absolute;
  width: 566px;
  height: 359px;
  margin-left: 280px;
  margin-top: 220px;
  transform: translate(-50%, -50%);
  opacity: 1;
`;

const HR = styled.div`
  width: 566px;
  height: 0px;
  left: 0px;
  margin-top: 0px;

  /* Primary/Blue */

  border: 2px solid #143551;
`;

const HR2 = styled.div`
  width: 566px;
  height: 0px;
  left: 0px;
  margin-top: 0px;

  border: 0.5px solid #143551;
`;
const ListDiv = styled.div`
  display: flex;
  height: ${(props) => props.height}px;
  width: 566px;
  /* background-color: yellow; // 화면 노랑색 배경 */
  align-items: center;
  justify-content: flex-start;
`;

const CustomButton4 = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 4px;
  width: 137px;
  height: 44px;
  background: #143551;
  border-radius: 40px;
  flex: none;
  order: 1;
  flex-grow: 0;
  color: white;
  border: none; /* 테두리 제거 */
  cursor: pointer;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
`;

const HGUImg = styled.img`
  height: 430px;
  width: 430px;
  margin-left: 55px;
`;

const NavLink = styled(Link)`
  text-decoration: none;
`;

const HomeOfficeHour = () => {
  const handleClick = () => {
    window.open(
      "https://map.naver.com/v5/entry/place/1816666857?lng=129.3881109&lat=36.1036575&placePath=%2Fhome%3Fentry=plt",
      "_blank"
    );
  };

  return (
    <Div>
      <ThemeProvider theme={theme}>
        <PartDiv>
          <TitleDiv>
            <Header1 left={0}>오피스 아워</Header1>
            <Caption1>
              오피스아워는 학우님들과 총학생회의 소통 창구로, 물품/시설 대여,{" "}
              <br />
              총학생회 게시판, 화광지, 학생경비 납부 등 다양한 서비스를 제공하는
              사업입니다.
            </Caption1>
            <Header1 left={60}>HGU SHOP</Header1>
            <NavLink to='/campinfo/hgushop'>
              <CustomButton>
                {"더보기 "}
                <span className="Arrow">{">"}</span>
              </CustomButton>
            </NavLink>
          </TitleDiv>
          <BodyDiv>
            <TableDiv>
              <HGUBackLogo
                src={require("../../../Assets/Img/Logo/HomeBackLogo.png")}
              />
              <HR />
              <ListDiv height={64}>
                <SemiTitle right={90}>오피스 위치</SemiTitle>
                <SemiBody>
                  학생회관 2층 다윗의 장막 맞은편 (이미지 스테이션 옆)
                </SemiBody>
              </ListDiv>
              <HR2 />
              <ListDiv height={84}>
                <SemiTitle right={90}>오피스 아워</SemiTitle>
                <SemiBody>
                  평일 13:00-14:00 / 16:30~18:30
                  <br />
                  (수요일은 13:00~14:00만 운영)
                </SemiBody>
              </ListDiv>
              <HR2 />
              <ListDiv height={150}>
                <SemiTitle right={73}>물품대여 방법</SemiTitle>
                <SemiBody>
                  1. 총학생회 네이버 예약 페이지에서 대여하고자 하는
                  <br />
                  &nbsp;&nbsp;&nbsp; 물품 또는 시설 선택 후, 예약 및 입금
                  <br /> 2. 예약 확정 받은 후, 오피스아워에 방문하여
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;담당자에게 예약 확정 문자 또는 화면
                  제시
                  <br />
                  3. 담당자에게 안내사항을 전달받고 절차에 따라 대여 진행
                </SemiBody>
              </ListDiv>
              <HR2 />
              <ListDiv height={106}>
                <SemiTitle right={69}>문의 및 연락처</SemiTitle>
                <SemiBody>
                  총학생회 중앙집행위원회 사무국 국장 김예린
                  <br />
                  (withhgu.management@gmail.com)
                  <br />
                  오피스 연락처 : 0507-1316-1631
                </SemiBody>
              </ListDiv>
              <HR2 />
              <ListDiv height={84}>
                <SemiTitle right={108}>물품 대여</SemiTitle>
                <CustomButton4 onClick={handleClick}>
                  대여 물품 리스트
                </CustomButton4>
              </ListDiv>
              <HR2 />
            </TableDiv>
            <HGUImg
              src={require("../../../Assets/Img/MainHome_Img/Home_HGU_Shop.png")}
            />
          </BodyDiv>
        </PartDiv>
      </ThemeProvider>
    </Div>
  );
};

export default HomeOfficeHour;
