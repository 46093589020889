import styled, { ThemeProvider } from 'styled-components';
import { theme } from '../../../Style/theme';
import React, { useState } from 'react';

const Header1 = styled.div`
  font-size: ${props => props.theme.Web_fontSizes.Header1};
  font-weight: ${props => props.theme.fontWeights.Header1};
  line-height: ${props => props.theme.LineHeight.Header1};
  font-family: 'Pretendard';
  text-decoration: none;
  margin-right: 50px;
  color: #3F3F37;
`;

const Header2 = styled.div`
  font-size: ${props => props.theme.Web_fontSizes.Header2};
  font-weight: ${props => props.theme.fontWeights.Header2};
  line-height: ${props => props.theme.LineHeight.Header2};
  font-family: 'Pretendard';
  text-decoration: none;
  width: 745px;
  color: #3F3F37;
  margin-top: ${props => props.top}px;
`;


const RightDiv = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 745px;
   height: 1000px;
   /* background-color: red; */
`;

const HR = styled.div`

width: 745px;
height: 0px;
left:0px;
margin-top: 56px;
border: 2px solid #143551;
`

const StuCouncilChartImg = styled.img`
   width: 799px;
   height: 348px;
   margin-top: 36px;
`;



const StuCouncilSecond = () => {

  return (
    <ThemeProvider theme={theme}>
      <RightDiv>
        <Header1>조직도</Header1>
        <HR />
        <Header2 top={48}>학생기구 조직도</Header2>
        <StuCouncilChartImg src={require('../../../Assets/Img/StudentCouncil_Img/StuUnionOrganizationChart.png')} />
        <Header2 top={45}>총학생회 조직도</Header2>
        <StuCouncilChartImg src={require('../../../Assets/Img/StudentCouncil_Img/StuCouncilOrganizationalChart.png')} />
      </RightDiv>
    </ThemeProvider>
  );
};

export default StuCouncilSecond;