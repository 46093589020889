import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { Link } from "react-router-dom";
import { theme } from "../../../Style/theme";
import Notice1 from "../../../Assets/Img/MainHome_Img/HomeNotice1.png";
import Notice2 from "../../../Assets/Img/MainHome_Img/HomeNotice2.png";
import Notice3 from "../../../Assets/Img/MainHome_Img/HomeNotice3.png";
import Notice4 from "../../../Assets/Img/MainHome_Img/HomeNotice4.png";

const Header1 = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Header1};
  font-weight: ${(props) => props.theme.fontWeights.Header1};
  line-height: ${(props) => props.theme.LineHeight.Header1};
  font-family: "Pretendard";
  text-decoration: none;
  color: #3f3f37;
  margin-left: ${(props) => props.left}px;
`;

const Header2 = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Header2};
  font-weight: ${(props) => props.theme.fontWeights.Header2};
  line-height: ${(props) => props.theme.LineHeight.Header2};
  font-family: "Pretendard";
  text-decoration: none;
  color: #3f3f37;
  width: 288px;
  margin-bottom: 24px;
  word-wrap: break-word;
  /* word-break: keep-all; // 글자 띄어쓰기 */
`;

const Body5 = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Body5};
  font-weight: ${(props) => props.theme.fontWeights.Body5};
  line-height: ${(props) => props.theme.LineHeight.Body5};
  font-family: "Pretendard";
  text-decoration: none;
  color: #3f3f37;
  word-wrap: break-word;
  /* word-break: keep-all; */
  width: 288px;
`;

const Div = styled.div`
  margin: 0px auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 1440px;
`;

const PartDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 750px;
  align-items: center;
  margin: 0px auto;
`;

const TitleDiv = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  width: 100%;
  margin-top: 80px;
`;

const CustomButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 4px;
  width: 68px;
  height: 30px;
  background: #143551;
  border-radius: 40px;
  flex: none;
  order: 1;
  flex-grow: 0;
  color: white;
  border: none; /* 테두리 제거 */
  cursor: pointer;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  margin-left: 865px;

  .Arrow {
    padding-bottom: 1.5px;
  }
`;

const BodyDiv = styled.div`
  display: flex;
  height: 425px;
  width: 1050px;
  /* background-color: blue; //화면 파랑색 배경 */
  margin-top: 24px;
`;

const BoxContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  width: 1100px;
  height: 100%;
`;

const Box = styled.div`
  border: 1px solid #ccc;
  display: flex;
  border: none;
  margin-bottom: 20px;
  margin-right: 15px;
`;

const Box2 = styled.div`
  margin-left: 16px;
`;

const Image = styled.img`
  width: 200px;
  height: 200px;
  margin-right: 5px;
`;

const NavLink = styled(Link)`
  text-decoration: none;
`;

const data = [
  {
    image: Notice1,
    title: "최대 30자 이내 두줄 짜리 제목을 적어줍니다",
    content:
      "5줄 정도의 내용을 노출 시킵니다. 더 많은 내용은 클릭해서 확인할 수 있습니다. 해당 게시물을 누르면 그 게시물의 원문과 사진이 함께 나오겠죠. 여기는 썸네일이 노출되는거구요. 3줄이 넘으면 내용을 잘라서 일부만 보이게 할 수 있습니다. 마치 이런식으로 보이...",
  },
  {
    image: Notice2,
    title: "최대 30자 이내 두줄 짜리 제목을 적어줍니다",
    content:
      "5줄 정도의 내용을 노출 시킵니다. 더 많은 내용은 클릭해서 확인할 수 있습니다. 해당 게시물을 누르면 그 게시물의 원문과 사진이 함께 나오겠죠. 여기는 썸네일이 노출되는거구요. 3줄이 넘으면 내용을 잘라서 일부만 보이게 할 수 있습니다. 마치 이런식으로 보이...",
  },
  {
    image: Notice3,
    title: "최대 30자 이내 두줄 짜리 제목을 적어줍니다",
    content:
      "5줄 정도의 내용을 노출 시킵니다. 더 많은 내용은 클릭해서 확인할 수 있습니다. 해당 게시물을 누르면 그 게시물의 원문과 사진이 함께 나오겠죠. 여기는 썸네일이 노출되는거구요. 3줄이 넘으면 내용을 잘라서 일부만 보이게 할 수 있습니다. 마치 이런식으로 보이...",
  },
  {
    image: Notice4,
    title: "최대 30자 이내 두줄 짜리 제목을 적어줍니다",
    content:
      "5줄 정도의 내용을 노출 시킵니다. 더 많은 내용은 클릭해서 확인할 수 있습니다. 해당 게시물을 누르면 그 게시물의 원문과 사진이 함께 나오겠죠. 여기는 썸네일이 노출되는거구요. 3줄이 넘으면 내용을 잘라서 일부만 보이게 할 수 있습니다. 마치 이런식으로 보이...",
  },
];

const HomeNotice = () => {
  return (
    <Div>
      <ThemeProvider theme={theme}>
        <PartDiv>
          <TitleDiv>
            <Header1 left={0}>공지 사항</Header1>
            <NavLink to="/notice">
              <CustomButton>
                {"더보기 "}
                <span className="Arrow">{">"}</span>
              </CustomButton>
            </NavLink>
          </TitleDiv>
          <BodyDiv>
            <BoxContainer>
              {data.map((item, index) => (
                <Box key={index}>
                  <Image src={item.image} alt="이미지" />
                  <Box2>
                    <Header2>{item.title}</Header2>
                    <Body5>{item.content}</Body5>
                  </Box2>
                </Box>
              ))}
            </BoxContainer>
          </BodyDiv>
        </PartDiv>
      </ThemeProvider>
    </Div>
  );
};

export default HomeNotice;
