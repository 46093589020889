import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { theme } from "../../../Style/theme";
import Calendar from "./Calendar_components";

const Header1 = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Header1};
  font-weight: ${(props) => props.theme.fontWeights.Header1};
  line-height: ${(props) => props.theme.LineHeight.Header1};
  font-family: "Pretendard";
  text-decoration: none;
  color: white;
  margin-left: ${(props) => props.left}px;
`;

const BigDiv = styled.div`
  width: 100%;
  background-color: #143551;
`;

const Div = styled.div`
  margin: 0px auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 1440px;
  background-color: #143551;
`;

const PartDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 800px;
  width: 100%;
  align-items: center;
  /* justify-content: center; */
  margin: 0px auto;
`;

const TitleDiv = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  width: 100%;
  margin-top: 80px;
`;

const BodyDiv = styled.div`
  display: flex;
  height: 488px;
  width: 1050px;
  /* background-color: blue; //화면 파랑색 배경 */
  margin-top: 24px;
`;

const TableDiv = styled.div`
  height: 488px;
  width: 536px;
  background-color: red; // 화면 빨간색 배경
`;

const HomeCalendar = () => {
  return (
    <BigDiv>
      <Div>
        <ThemeProvider theme={theme}>
          <PartDiv>
            <TitleDiv>
              <Header1 left={200}>캘린더</Header1>
            </TitleDiv>
            <BodyDiv>
              <TableDiv>
                <Calendar /> {/* 캘린더 컴포넌트 입니다 */}
              </TableDiv>
            </BodyDiv>
          </PartDiv>
        </ThemeProvider>
      </Div>
    </BigDiv>
  );
};

export default HomeCalendar;
