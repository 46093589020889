import React from 'react';
import Footer from '../Components/Web/Footer/Footer';
import NavBar from '../Components/Web/NavBar/Web_NavBar';
import StuCouncilFirst from '../Components/Web/StudentCouncil_Components/Web_StuCouncilFirst';
import StuCouncilSecond from '../Components/Web/StudentCouncil_Components/Web_StuCouncilSecond';

const StudentCouncil = () => {
  return (
    <div>
        <NavBar/>
        <StuCouncilFirst/>
        <Footer/>
    </div>
  );
};

export default StudentCouncil;
