import styled, { ThemeProvider } from 'styled-components';
import { theme } from '../../../Style/theme';
import {GoogleLogin} from "@react-oauth/google";
import {GoogleOAuthProvider} from "@react-oauth/google";


const Caption1 = styled.div`
  font-size: ${props => props.theme.Web_fontSizes.Caption1};
  font-weight: ${props => props.theme.fontWeights.Caption1};
  line-height: ${props => props.theme.LineHeight.Caption1};
  font-family: 'Pretendard';
  text-decoration: none;
  color: #9C9C9C;
  margin-top:12px;
`;

const Body4 = styled.div`
  font-size: ${props => props.theme.Web_fontSizes.Body4};
  font-weight: ${props => props.theme.fontWeights.Body4};
  line-height: ${props => props.theme.LineHeight.Body4};
  font-family: 'Pretendard';
  text-decoration: none;
  text-align: center;
  color: #9C9C9C;
  margin-top: ${props => props.top}px;
`;

const Header2 = styled.div`
  font-size: ${props => props.theme.Web_fontSizes.Header2};
  font-weight: ${props => props.theme.fontWeights.Header2};
  line-height: ${props => props.theme.LineHeight.Header2};
  font-family: 'Pretendard';
  text-decoration: none;
  text-align: center;
  color: white;
`;


const PartDiv = styled.div`
    height: 100vh;
    display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
width: 100%;
margin: 0 auto;
background-color: #F4FAFF;

`;
const Div = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
`;

const GoogleLoginButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  margin-top: 40px;
  width: 460px;
  height: 64px;
  background: #143551;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
  border: none;
  cursor: pointer;
`;

const GoogleLogo = styled.img`
  width: 24px;
  height: 24px;
`;

const HandongLogo = styled.img`
  width: 270px;
  height: 82px;
`;

const GoogleLoginButton1 = styled(GoogleLogin)`
  /* Add your custom styles here */
`;


function LoginPage() {
  const clientId = '886832019247-b1kimui1v0nu60pit0ofo2g0jnhjkve3.apps.googleusercontent.com'

  const responseGoogle = (response) => {
    console.log(response);
    // Handle the response from Google login here
  };

    return (
        <Div>
        <PartDiv>
            <ThemeProvider theme={theme}>
                <HandongLogo src={require("../../../Assets/Img/Logo/HGUTextLogo.png")} alt="Handog Logo" />
            <GoogleLoginButton >
      <GoogleLogo src={require("../../../Assets/Img/Logo/GoogleLogin_Logo.png")} alt="Google Logo" />
      <Header2>구글로 로그인하기</Header2>
    </GoogleLoginButton>
              <Caption1>* handong.edu / handong.ac.kr로만 로그인이 가능합니다. <br/>그 외 로그인이 필요하신 경우 아래 메일로 문의 주세요.</Caption1>
              <Body4 top={40}>문의 : 한동대학교 제28대 총학생회 중앙집행위원회 'WITH'(withhgu@gmail.com)</Body4>
              <Body4 top={16}>37554 경북 포항시 북구 흥해읍 한동로 558 한동대학교 
              <br/>Tel: 054-60-1111 Fax: 054-260-1149
              <br/>Copyright 2023. HANDONG GLOBAL UNIVERSITY All Rights Resesrved.</Body4>
              <GoogleOAuthProvider clientId={clientId}>
                <GoogleLogin
                    onSuccess={(res) => {
                        console.log(res);
                    }}
                    onFailure={(err) => {
                        console.log(err);
                    }}
                />
            </GoogleOAuthProvider>
            </ThemeProvider>
            
        </PartDiv>
        </Div>
    );
}

export default LoginPage;




