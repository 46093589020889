import React, { useState, useEffect } from "react";
import styled, { ThemeProvider } from "styled-components";
import { theme } from "../../../Style/theme";
import DumeDataImg from "../../../Assets/Img/ConvenienceInfo_Img/HGUShop/Dataimg.png";
import DropDownArrow from "../../../Assets/Img/NoticeImg/DropDownIcon.png";
import { Link } from "react-router-dom";

const dummyData = [];
for (let i = 1; i <= 100; i++) {
  dummyData.push({
    id: i,
    image: DumeDataImg,
    title: `Dummy Title ${i}`,
    author: `Author ${i}`,
    date: "2023-07-01",
    views: 100,
    category: "카테고리",
  });
}

const Header1 = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Header1};
  font-weight: ${(props) => props.theme.fontWeights.Header1};
  line-height: ${(props) => props.theme.LineHeight.Header1};
  font-family: "Pretendard";
  text-decoration: none;
  color: #3f3f37;
  margin-bottom: 16px;
`;

const Header2 = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Header2};
  font-weight: ${(props) => props.theme.fontWeights.Header2};
  line-height: ${(props) => props.theme.LineHeight.Header2};
  font-family: "Pretendard";
  color: #3f3f37;
`;

const Caption1 = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Caption1};
  font-weight: ${(props) => props.theme.fontWeights.Caption1};
  line-height: ${(props) => props.theme.LineHeight.Caption1};
  font-family: "Pretendard";
  color: #3f3f37;
`;

const Caption4 = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Caption4};
  font-weight: ${(props) => props.theme.fontWeights.Caption4};
  line-height: ${(props) => props.theme.LineHeight.Caption4};
  font-family: "Pretendard";
  color: #cfd4d9;
  margin-top: 160px;
  margin-bottom: 8px;
`;

const Div = styled.div`
  margin: 0px auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 100%;
`;

const PartDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 1870px;
  width: 1047px;
  align-items: center;
  margin: 0px auto;
  margin-top: 100px;
  /* background-color: red; */
`;

const TitleDiv = styled.div`
  display: flex;
  height: 38px;
  justify-content: start;
  width: 100%;
  align-items: center;
  /* background-color: green; */
  margin-bottom: 56px;
`;

const HRLight = styled.div`
  position: absolute;
  width: 99.9vw;
  height: 0px;
  left: 0px;
  margin-top: 40px;
  border: 0.8px solid #d0d0d0;
  overflow-x: hidden;
`;

const HRThick = styled.div`
  position: absolute;
  width: 1040px;
  height: 0px;
  display: flex;
  margin-top: 115px;
  border: 3px solid #143551;
`;

const Home = styled.img`
  width: 16px;
  height: 16px;
  margin-left: 2px;
`;

const RightAllow = styled.img`
  width: 5px;
  height: 9px;
  margin-left: 8px;
`;

const WhereText = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  font-family: "Pretendard";
  text-decoration: none;
  color: #3f3f37;
  margin-left: 8px;
`;

const MainDiv = styled.div`
  /* background-color: blue; */
  width: 100%;
  height: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PageButtonDiv = styled.div`
  width: 350px;
  height: 32px;
  /* background-color: green; */
  margin-top: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FirstArrowButton = styled.button`
  width: 32px;
  height: 32px;
  border: 1px solid #9c9c9c;
  border-right: ${(props) => props.border}px solid #9c9c9c;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${(props) => props.right}px;
  cursor: pointer;
`;

const PageButton = styled.div`
  font-size: 16px;
  font-family: "Pretendard";
  font-weight: 600;
  line-height: 20px;
  color: ${(props) => (props.isActive ? "#3F3F37" : "#D0D0D0")};
  width: 10px;
  height: 20px;
  margin-left: 32px;
  cursor: pointer;
`;

const BeforeButton = styled.button`
  width: 32px;
  height: 32px;
  border: 1px solid #9c9c9c;
  border-left: 0.5px solid #9c9c9c;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -8px;
  cursor: pointer;
`;

const SingleArrow = styled.img`
  width: 6.5px;
  height: 8px;
`;

const PageArrowButton = styled.button`
  width: 32px;
  height: 32px;
  border: 1px solid #9c9c9c;
  border-right: 0.5px solid #9c9c9c;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 24px;
  cursor: pointer;
`;

const DoubleArrow = styled.img`
  width: 12.5px;
  height: 8px;
`;

const LastArrowButton = styled.button`
  width: 32px;
  height: 32px;
  border: 1px solid #9c9c9c;
  border-left: 0.5px solid #9c9c9c;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const SearchInputDiv = styled.div`
  width: 990px;
  /* background-color: green; */
  height: 36px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
`;

const DropdownButton = styled.select`
  width: 100px;
  height: 36px;
  justify-content: center;
  align-items: center;
  display: flex;
  padding-left: 8px;
  border: 1px solid #d0d0d0;
  border-right: none;
  align-items: center;
  font-size: 16px;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: ${(props) => (props.value ? "black" : "#d0d0d0")};
  appearance: none; /* Hide the default arrow */
  -webkit-appearance: none; /* For Safari */
  -moz-appearance: none; /* For Firefox */
  background-image: url(${DropDownArrow});
  background-repeat: no-repeat;
  background-position: 82px 13px;
  background-size: 12px 10px;
  outline: none;
  cursor: pointer;
`;

const SearchInput = styled.input`
  width: 165px;
  padding: 8px 56px 8px 8px;
  border: 1px solid #d0d0d0;
  border-right: none;
  color: #3f3f37;
  font-size: 16px;
  font-family: "Pretendard";
  line-height: 20px;
  outline: none;
  &::placeholder {
    color: #d0d0d0;
  }
  &:focus::placeholder {
    color: #3f3f37;
  }
`;

const SearchInputButton = styled.button`
  width: 36px;
  height: 36px;
  background-color: #3f3f37;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const SearchIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const NoContentsImg = styled.img`
  width: 80px;
  height: 80px;
`;

const NoticeComponents = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchItemSetting, setSearchItemSetting] = useState("");
  const [selectedCategorySetting, setSelectedCategorysetting] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const itemsPerPage = 16;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const [totalPages, setTotalPages] = useState(0);
  const filteredData = dummyData
    .filter((item) => {
      if (selectedCategory === "") {
        return item.title.toLowerCase().includes(searchTerm.toLowerCase());
      } else {
        return item[selectedCategory.toLowerCase()]
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      }
    })
    .slice(startIndex, endIndex);

  useEffect(() => {
    const filteredData = dummyData.filter((item) => {
      if (selectedCategory === "") {
        return item.title.toLowerCase().includes(searchTerm.toLowerCase());
      } else {
        return item[selectedCategory.toLowerCase()]
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      }
    });

    setTotalPages(Math.ceil(filteredData.length / itemsPerPage));
  }, [searchTerm, selectedCategory]);

  const handleCategoryChange = (event) => {
    setSelectedCategorysetting(event.target.value);
  };

  const handleSearch = (event) => {
    setSearchItemSetting(event.target.value);
  };

  const handleSearchButton = (event) => {
    setSearchTerm(searchItemSetting);
    setSelectedCategory(selectedCategorySetting);
    setCurrentPage(1);
    window.scrollTo(0, 0);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
    window.scrollTo(0, 0);
  };

  const handleNextPage = () => {
    const nextPage = Math.ceil(currentPage / 5) * 5 + 1;
    if (nextPage <= totalPages) {
      setCurrentPage(nextPage);
      window.scrollTo(0, 0);
    } else {
      setCurrentPage(totalPages);
      window.scrollTo(0, 0);
    }
  };

  const handleBeforePage = () => {
    if (currentPage > 1) {
      const currentRangeStart = Math.floor((currentPage - 1) / 5) * 5 + 1;
      setCurrentPage(currentRangeStart - 5);
      window.scrollTo(0, 0);
    }
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
    window.scrollTo(0, 0);
  };

  const BoxComponent = ({ data }) => {
    const BoxGrid = styled.div`
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      row-gap: 48px;
      column-gap: 32px;
      margin-top: 40px;
    `;

    const PreviewImg = styled.img`
      width: 224px;
      height: 224px;
    `;

    const FirstDiv = styled.div`
      width: 224px;
      height: 22px;
      display: flex;
      margin-top: 24px;
    `;

    const SecondDiv = styled.div`
      width: 224px;
      height: 16px;
      display: flex;
      margin-top: 16px;
    `;
    const BoxItem = styled(Link)`
      cursor: pointer;
      border: none;
      text-decoration: none;
    `;

    return (
      <BoxGrid>
        {data.map((item) => (
          <BoxItem to={`/notice/${item.id}`} key={item.id}>
            {" "}
            <PreviewImg src={item.image} alt={item.title} />
            <FirstDiv>
              <Header2>[{item.category}]</Header2>
              <Header2>&nbsp;{item.title}</Header2>
            </FirstDiv>
            <SecondDiv>
              <Caption1>{item.date}&nbsp;</Caption1>
              <Caption1>|&nbsp;{item.views}&nbsp;</Caption1>
              <Caption1>|&nbsp;{item.author}</Caption1>
            </SecondDiv>
          </BoxItem>
        ))}
      </BoxGrid>
    );
  };

  return (
    <Div>
      <ThemeProvider theme={theme}>
        <PartDiv>
          <TitleDiv>
            <Home
              src={require("../../../Assets/Img/StudentCouncil_Img/HomeIcon.png")}
            />
            <RightAllow
              src={require("../../../Assets/Img/StudentCouncil_Img/MiniRightIcon.png")}
            />
            <WhereText>공지사항</WhereText>
          </TitleDiv>
          <HRLight />
          <MainDiv>
            <Header1>공지사항</Header1>
            <SearchInputDiv>
              <DropdownButton
                value={selectedCategory}
                onChange={handleCategoryChange}
              >
                <option value="title">제목</option>
                <option value="date">내용</option>
                <option value="author">작성자</option>
              </DropdownButton>
              <SearchInput
                type="text"
                placeholder="검색어를 입력해 주세요."
                value={searchItemSetting}
                onChange={handleSearch}
              />
              <SearchInputButton onClick={handleSearchButton}>
                <SearchIcon
                  src={require("../../../Assets/Img/NoticeImg/SearchIcon.png")}
                />
              </SearchInputButton>
            </SearchInputDiv>
            <HRThick />
            <BoxComponent data={filteredData} />
            {totalPages === 0 ? (
              <>
                <Caption4>아직 컨텐츠가 없습니다.</Caption4>
                <NoContentsImg
                  src={require("../../../Assets/Img/NoticeImg/NoContentsImg.png")}
                />
              </>
            ) : (
              <PageButtonDiv>
                {currentPage === 1 ? (
                  <></>
                ) : totalPages < 5 ? (
                  <></>
                ) : currentPage > 5 ? (
                  <>
                    <FirstArrowButton
                      border={0.5}
                      right={0}
                      onClick={handleFirstPage}
                      disabled={currentPage === 1}
                    >
                      <DoubleArrow
                        src={require("../../../Assets/Img/ConvenienceInfo_Img/HGUShop/MoveFirstArrow.png")}
                      />
                    </FirstArrowButton>
                    <BeforeButton
                      onClick={handleBeforePage}
                      disabled={currentPage === 1}
                    >
                      <SingleArrow
                        src={require("../../../Assets/Img/ConvenienceInfo_Img/HGUShop/BackArrow.png")}
                      />
                    </BeforeButton>
                  </>
                ) : (
                  <FirstArrowButton
                    border={1}
                    right={-8}
                    onClick={handleFirstPage}
                    disabled={currentPage === 1}
                  >
                    <DoubleArrow
                      src={require("../../../Assets/Img/ConvenienceInfo_Img/HGUShop/MoveFirstArrow.png")}
                    />
                  </FirstArrowButton>
                )}
                {Array.from({ length: totalPages }, (_, index) => index + 1)
                  .filter(
                    (pageNumber) =>
                      pageNumber >= (Math.ceil(currentPage / 5) - 1) * 5 + 1 &&
                      pageNumber <= (Math.ceil(currentPage / 5) - 1) * 5 + 5
                  )
                  .map((pageNumber) => (
                    <PageButton
                      key={pageNumber}
                      onClick={() => handlePageChange(pageNumber)}
                      disabled={pageNumber === currentPage}
                      isActive={pageNumber === currentPage}
                    >
                      {pageNumber}
                    </PageButton>
                  ))}
                {currentPage === totalPages ? (
                  <></>
                ) : totalPages < 5 ? (
                  <></>
                ) : (
                  <>
                    <PageArrowButton
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                    >
                      <SingleArrow
                        src={require("../../../Assets/Img/ConvenienceInfo_Img/HGUShop/NextArrow.png")}
                      />
                    </PageArrowButton>
                    <LastArrowButton
                      onClick={handleLastPage}
                      disabled={currentPage === totalPages}
                    >
                      <DoubleArrow
                        src={require("../../../Assets/Img/ConvenienceInfo_Img/HGUShop/MoveLastArrow.png")}
                      />
                    </LastArrowButton>
                  </>
                )}
              </PageButtonDiv>
            )}
          </MainDiv>
        </PartDiv>
      </ThemeProvider>
    </Div>
  );
};

export default NoticeComponents;
